import Axios from 'axios'
import React, { Component } from 'react'
// import './SingleBrand.css'
import avtar from '../../../assets/avtar.jpeg'
import { Link } from 'react-router-dom'
import moment from "moment";
import Spinner from '../../../Containers/Spinner/Spinner'
import Modal from '../../../Containers/Modal/Modal'
import { Masonry } from '@mui/lab';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
// import type {} from '@mui/lab/themeAugmentation';

import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "../../../context/withRouter";

export class SingleBrand extends Component {
    constructor(props) {
        super(props)

        this.state = {
            singleBrand: {},
            contentArray: [],
            error: {
                message: '',
                code: ''
            },
            isloading: false,
            show: false
        }

    }

    componentDidMount() {

        this.setState(pre => ({
            isloading: true
        }))
        let id = this.props.match.params.id
        // Axios.get('/brands/brands/brand/' + id).then(res => {
        Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/brand/" + id).then(res => {
          console.log(res.data)
            this.setState({ ...this.state, singleBrand: res.data, isloading: false });
            console.log(this.state)
            return Axios.get("/api/categories/byname/" + res.data.categories[0].name)
        }).then(data => {
            this.setState({ ...this.state, articles: data.data.profile, isloading: false });
            console.log(this.state)
        }).catch(e => {
                this.setState({
                    isloading: false,
                    error: {
                        ...this.state.error, message: e.response.data.message,
                        code: e.response.status
                    }
                });
            })

    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };


    deletePost=(id)=>{
        this.setState(pre => ({
            isloading: true
        }))
       Axios.delete("/blog/posts/"+id).then(data=>{
        this.setState(pre => ({
            isloading: false
        }))
        this.props.history.push('/mypost')
       })
       .catch(e=>{
        this.setState({
            isloading: false,
            error: {
                ...this.state.error, message: e.response.data.message,
                code: e.response.status
            }
        });
       })
    }

    render() {
        let isLoading
        let iserror

        if (this.state.isloading) {
            isLoading = (
                <>
                    <div className="container loading">
                        <div className="mar-20">
                            <Spinner />
                        </div>
                    </div>
                </>
            )
        }

        if (this.state.error.code) {
            iserror = (
                <>
                    <div className="container error container-short">
                        <div className="mar-20">
                            <h5>Error Code - {this.state.error.code}</h5>
                            <h4>Error Message - {this.state.error.message}</h4>
                        </div>
                    </div>
                </>
            )
        }

        let post
        let post1 = this.state.singleBrand
        let user = this.state.user
        let categories
        let articles

        articles = (<>
          <div className="row">
          {this.state.articles?.splice(0,25).map((article, index) => (
            <div className="col s12">
              <div className="card-panel teal">
              <h6><a href={article.link} style={{fontWeight:"bolder",color: 'rgb(0, 0, 0)'}}>{article.title}</a></h6>
              <p>Source: {article.source}</p>
                {/*<span class="white-text">I am a very simple card. I am good at containing small bits of information.
                  I am convenient because I require little markup to use effectively. I am similar to what is called a panel in other frameworks.
                </span>*/}
              </div>
            </div>
          ))}
          </div>
          </>)

        categories = (<>
          {post1.categories?.map((post, index) => (
            <p>{post.name}</p>
          ))}
          </>)

        // console.log(user)
        // console.log(this.state.singleBrand.content)
        // if (this.state.singleBrand.content === Array) {
          // console.log(this.state)

            post = (<>
                <div className="singleBrand_content pt-3">
                    <img src={post1.imagePath} className="img-fluid" alt="prof" style={{width: '100%', height: '300px', objectFit: 'cover'}} />
                    <h2>{post1.title}</h2>
                    <div className="SingleBlog_avatar">
                        <Link to={"/public/" + user?.username} rel="noopener noreferrer" >
                            {/*<img src={user?.imagePath ? user.imagePath : avtar} alt="img" width="75" height="75" />*/}
                        </Link>

                        <Link to={"/public/" + user?.username} rel="noopener noreferrer" >
                            <p>{user?.username}</p>
                        </Link>
                        <p>{moment(post1.postDate).format("MMM DD, YYYY hh:mm")}</p>
                    </div>
                    <div className="text pt-3">
                        {/*<p>{post1.content}</p>*/}
                    </div>
                    {/*<div>
                    {post1.content.map((post, index) => (
                      <p>{post}</p>
                    ))}
                    </div>*/}
                </div>
            </>
            )
        /*} else {
          console.log('hi')
          post = (<>
          </>)
        }*/
        return (<>
            {isLoading}
            {iserror}
            <div className="container py-4 SingleBlog " style={{}}>

            <div className="row">
              <div className="col s12 center-align">
                <h4>
                  <b>Hey there,</b> {this.state.singleBrand.name}
                  </h4>
                  </div>
                  <div className="col s12">
                  {/*<p className="flow-text grey-text text-darken-1">
                    You are logged into a full-stack{" "}
                    <span style={{ fontFamily: "monospace" }}>MERN</span> app 👏
                  </p>*/}
                  <p className="flow-text grey-text text-darken-1">
                  </p>
                </div>
              </div>

            <Masonry columns={{ xs: 1, sm: 2, md: 3 }} sx={{ width: "auto" }} spacing={2}>
                {this.state.articles?.slice(0,10).map((size, index) => (
                    // <Paper key={index} sx={{ size }}>
                    <div key={index} sx={{ size }} className="card-panel" style={{border: `2px solid ${this.state.singleBrand.color}`, background:'#f3f3f3'}}>
                        <h6><a href={size.link} style={{fontWeight:"bolder",color: 'rgb(0, 0, 0)'}}>{size.title}</a></h6>
                        <label>Source: {size.source}</label>
                      </div>
                    // </Paper>
                ))}
            </Masonry>

              {/*{articles}*/}

                <div className="row">
                    {this.props.match.path === `/mypost/${this.props.match.params.id}` &&
                        <div className="col-md-3 col-xs-12 ">
                            <div className="list-group">
                                <Link to={"/edit/" + post1._id} className="list-group-item list-group-item-action">Edit</Link>
                                <button type="button"  onClick={this.showModal} className="list-group-item list-group-item-action">Delete</button>
                            </div>
                        </div>}
                    <div className="col-md-9 col-xs-12 main">
                        <Modal show={this.state.show} handleClose={this.hideModal}>
                            <div className="modal-header">
                            <h2>{post1.title}</h2>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.hideModal}>&times;</button>

                            </div>
                            <div className="modal-body">
                                Confirm delete
                           </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={()=>this.deletePost(post1._id)}>Delete </button>
                                <button type="button" className="btn btn-outline-dark" onClick={this.hideModal} data-dismiss="modal">Close</button>
                            </div>
                        </Modal>
                        {/*{post}*/}
                        {/*<img src={this.state.contentArray[0]?.media} className="img-fluid" alt="prof" style={{width: '100%', objectFit: 'cover'}} />*/}
                        {/*<h2 style={{fontWeight:'Bolder'}}>{this.state.contentArray[0]?.heading}</h2>*/}

                        {/*<p style={{fontSize:'20px',lineHeight:'32px'}}>{this.state.contentArray[0]?.body}</p>
                        {this.state.singleBrand.categories?.map((element, index) => (
                          <div key={index} style={{padding: '50px 0',}}>
                          <img src={element.media} className="img-fluid" alt="prof" style={{width: '100%', objectFit: 'cover'}} />
                          <h4 style={{color:'#e3bab3', fontWeight:'Bolder'}}>{element.name}</h4>
                          {element.subheading
                          ? <h6>{element.subheading}</h6>
                          : null}
                          <p style={{fontSize:'20px',lineHeight:'32px'}}>{element.body}</p>
                          </div>
                        ))}*/}
                    </div>
                </div>
            </div>
        </>
        )
    }
}

const mapStateToProps = (state,props) => ({
  auth: state.auth,
});

export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(SingleBrand);

// export default SingleBrand
