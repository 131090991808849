import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';


// let userData =  JSON.parse(localStorage.getItem("userData"))

axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'https://timbrtech.onrender.com'// process.env.API_URL;
// axios.defaults.baseURL = 'http://localhost:5000'; 'http://192.168.2.16:5000'
const root = ReactDOM.createRoot(document.getElementById('root'));
// let userData =  JSON.parse(localStorage.getItem("userData"))
// console.log(localStorage)

axios.defaults.headers.post['Content-Type'] = 'application/json';

    /*axios.interceptors.request.use(request => {
    //    console.log(request)

        // Edit request config
        return request;
    }, error => {
      //  console.log(error);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        // Edit response config
        //console.log(response);
        return response;
    }, error => {
        console.log(error.response);
        return Promise.reject(error);
    });*/

root.render(
  /*<React.StrictMode>*/
    <App />
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
