import React, {Component, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Axios from 'axios';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Spinner from '../../Containers/Spinner/Spinner';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableRow, TablePagination } from "@mui/material";

import { compose } from 'redux';
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { withRouter } from "../../context/withRouter";

function GetBrands(props) {
  console.log(props.value.auth.user.id)
  // return <span>Hi</span>
  let to = []
  Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/brands/" + props.value.auth.user.id).then(data => {
    console.log(data)
    // to.push(data.status)
    this.setState({brands:data.status})
    // return <span>{data.status}</span>
  })
  .catch(e => {
    // return <div>{e}</div>
  })
  return <p>{to}</p>

}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
          {/*<Typography><GetBrands></GetBrands></Typography>*/}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export class BasicTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value:0,
      page:0,
      rowsPerPage:5
     };
    this.handleChange = this.handleChange.bind(this)
    // this.mySubmitHandler = this.mySubmitHandler.bind(this)
  }

  componentDidMount() {
    console.log(this.props.auth)
    Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/brands/" +  this.props.auth.user.id).then(data => {
      console.log(data)
      // to.push(data.status)
      this.setState({brands:data.data})
      // return <span>{data.status}</span>
    })
    .catch(e => {
      // return <div>{e}</div>
    })
  }

  handleChange(event, newValue) {
    this.setState({value:newValue})
    if (newValue === 1 && !this.state.contents) {
      Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/contents/" +  this.props.auth.user.id).then(data => {
        console.log(data)
        // to.push(data.status)
        this.setState({contents:data.data})
        // return <span>{data.status}</span>
      })
      .catch(e => {
        // return <div>{e}</div>
      })
    }
    if (newValue === 2 && !this.state.jobs) {
      Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/jobs/" +  this.props.auth.user.id).then(data => {
        console.log(data)
        // to.push(data.status)
        this.setState({jobs:data.data})
        // return <span>{data.status}</span>
      })
      .catch(e => {
        // return <div>{e}</div>
      })
    }
    // console.log(newValue)
    // setValue(newValue);
  }

  CheckBrand() {
    return <span>bye</span>
  }

  handleChangePage(event, newPage) {
    // setPage(newPage);
    console.log(event.target.dataset)
    console.log(event.target.parentElement.ariaLabel)
    console.log(event.target.parentElement.dataset)
    console.log(event.target.ariaLabel)
    console.log(event)
    if (event.target.dataset.testid === 'KeyboardArrowLeftIcon' || event.target.parentElement.dataset.testid === 'KeyboardArrowLeftIcon' && this.state.page>0) {
      this.setState({page:newPage-1})
    }
    if (event.target.dataset.testid === 'KeyboardArrowRightIcon' || event.target.parentElement.dataset.testid === 'KeyboardArrowRightIcon' && newPage<=this.state.contents.lenth) {
      this.setState({page:newPage+1})
    }
    // event.target.dataset.testid === 'KeyboardArrowLeftIcon' || event.target.parentElement.dataset.testid === 'KeyboardArrowLeftIcon' ? this.setState({page:newPage-1}) : this.setState({page:newPage+1})
    // this.setState({page:newPage})
  };

  handleChangeRowsPerPage(event) {
    // setRowsPerPage(parseInt(event.target.value, 10));
    console.log(event.target.value)
    // this.setState({rowsPerPage:parseInt(event.target.value, 10)})
    // this.setState({page:0})
    // setPage(0);
  };

  render() {
  return (
    <div>
    <Tabs value={this.state.value} onChange={this.handleChange} style={{background:'#f3f3f3'}}>
        <Tab label='Brands' />
        <Tab label='Contents' />
        <Tab label='Jobs' />
        {/*<Tab label='Tab 4' />*/}
      </Tabs>

      {/* TAB 1 Contents */}
      {this.state.value === 0 && (

        <Box sx={{ p: 0 }}>
          {this.state.brands ?
            <><TableContainer sx={{ maxHeight: '100%' }}>
            <Table stickyHeader aria-label="sticky table" style={{marginBottom:'0px'}}>
            <TableBody style={{padding:'0px'}}>
            {this.state.brands.map((brand, index) => (
              <TableRow key={index}>
                <TableCell key={index} style={{fontSize:'1.2rem', fontWeight:500}}>
                  <div style={{display:'flex', alignItems:'center'}}><Avatar alt="Remy Sharp" src={brand.logo} style={{marginRight:'10px',objectFit:'contain', background:'#bdbdbd'}} /> <a href={"/brand/" + brand._id}>{brand.name}</a></div>
                </TableCell>
              </TableRow>
              /*<p key={index}>{brand.name}</p>*/
            ))}
            </TableBody>
            </Table>
            </TableContainer>
            </>
          : null /*<a className="main-btn btn btn-large waves-effect waves-light hoverable white-text accent-3 timbrBtn" href="/createBrand">Create Brand</a>*/ }
          <div style={{marginTop: '25px'}}>
          <a className="main-btn btn btn-large waves-effect waves-light hoverable white-text accent-3 timbrBtn" href="/createBrand">Create Brand</a>
          </div>
        </Box>

      )}

      {/* TAB 2 Contents */}
      {this.state.value === 1 && (
        <Box sx={{ p: 0 }}>
        {this.state.contents ?
          <><TableContainer sx={{ maxHeight: '400px' }}>
          <Table stickyHeader aria-label="sticky table">
          <TableBody style={{padding:'0px'}}>
          {this.state.contents.slice(this.state.page*5,(this.state.page+1)*5).map((content, index) => (
            <TableRow key={index}>
              <TableCell key={index} style={{fontSize:'1.2rem', fontWeight:500}}>
                <div style={{display:'flex', alignItems:'center'}}><Avatar alt="Remy Sharp" src={content.image || content.media} style={{marginRight:'10px',objectFit:'contain', background:'#bdbdbd'}} /> <a href={"/create/" + content._id}>{content.title}</a></div>
              </TableCell>
            </TableRow>
            /*<p key={index}>{brand.name}</p>*/
          ))}
          </TableBody>
          </Table>
          </TableContainer>
          {<TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={this.state.contents.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={e => this.handleChangePage(e,this.state.page)}
            onRowsPerPageChange={e => this.handleChangeRowsPerPage(e)} />}
            {/*<div style={{marginTop: '25px'}}>
            <a className="main-btn btn btn-large waves-effect waves-light hoverable white-text accent-3 timbrBtn" href="/create">Create Content</a>
            </div>*/}
          </>
        : null }
        <div style={{marginTop: '25px'}}>
        <a className="main-btn btn btn-large waves-effect waves-light hoverable white-text accent-3 timbrBtn" href="/create">Create Content</a>
        </div>
        </Box>
      )}

      {/* TAB 3 Contents */}
      {this.state.value === 2 && (
        <Box sx={{ p: 3 }}>
        {this.state.jobs ?
          <TableContainer sx={{ maxHeight: '100%' }}>
          <Table stickyHeader aria-label="sticky table">
          <TableBody style={{padding:'0px'}}>
          {this.state.jobs.map((job, index) => (
            <TableRow key={index}>
              <TableCell key={index} style={{fontSize:'1.2rem', fontWeight:500}}>
                <div style={{display:'flex', alignItems:'center'}}><Avatar alt="Remy Sharp" src={job.content.media} style={{marginRight:'10px',objectFit:'contain', background:'#bdbdbd'}} /> <a href={"/job/" + job._id}>{job.content.title}</a></div>
              </TableCell>
            </TableRow>
            /*<p key={index}>{brand.name}</p>*/
          ))}
          </TableBody>
          </Table>
          </TableContainer>
        : null }
        </Box>
      )}

      {/* TAB 4 Contents */}
      {/*this.state.value === 3 && (
        <Box sx={{ p: 3 }}>
          <Typography variant='h5'>Tab 4 Content</Typography>
          <Typography variant='p'>
The standard chunk of Lorem Ipsum used since the 1500s is reproduced
below for those interested. Sections 1.10.32 and 1.10.33 from "de
Finibus Bonorum et Malorum" by Cicero are also reproduced in their
exact original form, accompanied by English versions from the 1914
translation by H. Rackham.
          </Typography>
        </Box>
      )*/}
    {/*<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={this.state.value} onChange={this.handleChange} aria-label="basic tabs example">
          <Tab label="Brands" {...a11yProps(0)} />
          <Tab label="Content" {...a11yProps(1)} />
          <Tab label="Jobs" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={this.state.value} auth={this.props} index={0}>
        <GetBrands value={this.props}></GetBrands>
      </TabPanel>
      <TabPanel value={this.state.value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={this.state.value} index={2}>
        Item Three
      </TabPanel>
    </Box>*/}
    </div>
  );
}
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(BasicTabs);
