import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Axios from 'axios'
import Spinner from '../../Containers/Spinner/Spinner'
import BasicTabs from '../../components/layout/userMenu';
class Dashboard extends Component {

  constructor(props) {
      super(props)

      this.state = {
          brands: [],
          error: {
              message: '',
              code: ''
          },
          isloading: false,
          show: false
      }

  }

  componentDidMount() {

      this.setState(pre => ({
          isloading: false
      }))
      let id = this.props.auth.user.id
      console.log(this.props.auth.user.id)
      /*Axios.get('/brands/brands/mybrands').then(res => {
        console.log(res)
          // this.setState({ ...this.state.singlePost, singlePost: res.data, contentArray:res.data.content, isloading: false });
          this.setState({brands:res.data.profile,isloading: false})
          console.log(res.data.profile)
          console.log(this.state)
          // return Axios.get("/blog/profile/bycreator/" + res.data.creator)
      }).catch(e => {
              this.setState({
                  isloading: false,
                  error: {
                      ...this.state.error, message: e.response.data.message,
                      code: e.response.status
                  }
              });
          })*/

  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
render() {
  let isLoading
  let iserror

  if (this.state.isloading) {
      isLoading = (
          <>
              <div className="container loading">
                  <div className="mar-20">
                      <Spinner />
                  </div>
              </div>
          </>
      )
  }

  if (this.state.error.code) {
      iserror = (
          <>
              <div className="container error container-short">
                  <div className="mar-20">
                      <h5>Error Code - {this.state.error.code}</h5>
                      <h4>Error Message - {this.state.error.message}</h4>
                  </div>
              </div>
          </>
      )
  }

  const { brands } = this.state;
  const { user } = this.props.auth;
  console.log(brands)
  var names = brands.map(x=>x.name)
  const Cards = ({brands}) => (
    <>
    {/*brands.map(station => (
      <div className="card-panel teal">
        <span className="white-text">I am a very simple card. I am good at containing small bits of information.
        I am convenient because I require little markup to use effectively. I am similar to what is called a panel in other frameworks.
        </span>
      </div>
    ))*/}
    </>
  );
  const Test = ({brands}) => (
  <div>
  <BasicTabs />
  {/*<h3>Brands you manage</h3>
  <table className="table table-hover table-bordered p-5">
        <thead>
          <tr>
            <th scope="col">Logo</th>
            <th scope="col">Name</th>
            <th scope="col">Handle</th>
          </tr>
        </thead>
        <tbody>
          {brands.map((station,index) => (
            <tr key={index}>
              <td style={{}}><img src={station.logo} style={{height:'50px',width:'50px',objectFit: 'contain', objectPosition: 'center', verticalAlign: 'middle'}}></img></td>
              <td><a href={`/brand/${station._id}`} style={{}}>{station.name}</a></td>
              <td>{station.color}</td>
            </tr>
          ))}
        </tbody>
  </table>*/}
  {/*<div style={{marginTop: '25px'}}>
  <a className="main-btn btn btn-large waves-effect waves-light hoverable white-text accent-3 timbrBtn" href="/createBrand">Create Brand</a>
  </div>*/}
  </div>
);


  /*let brand
      brand = (<> {this.state.brands.map((item) =>{
        <p>hi</p>
      })}</>)*/

return (<>
      {isLoading}
      {iserror}
      <div className="container" style={{minHeight:'65vh'}}>

      <div className="row">
        <div className="col s12 center-align">
          <h4>
            <b>Hey there,</b> {user.name.split(" ")[0]}
            {/*<p className="flow-text grey-text text-darken-1">
              You are logged into a full-stack{" "}
              <span style={{ fontFamily: "monospace" }}>MERN</span> app 👏
            </p>*/}
          </h4>
        </div>
      </div>

        <Test brands={brands} />

        {/*<div><button
          style={{
            width: "150px",
            borderRadius: "3px",
            letterSpacing: "1.5px",
            marginTop: "1rem"
          }}
          onClick={this.onLogoutClick}
          className="btn btn-large waves-effect waves-light hoverable blue accent-3"
        >
          Logout
        </button></div>*/}

      </div>
    </>);
  }
}
Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(Dashboard);
