import React, { Component, Switch } from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { BrowserRouter as Router, useLocation, Routes, Route, Link,useNavigate,useParams, } from "react-router-dom";
import "./App.css";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/footer";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Posts from './components/posts/Posts';
import Blog from './components/posts/Preview';
import { AuthContext } from './context/auth-context'
import CreateProfile from './components/Users/CreateProfile/CreateProfile'
import CreatePost from './components/posts/CreatePost/CreatePost';
import Profile from './components/Users/Profile/Profile';
import CreateBrand from './components/brands/CreateBrand/CreateBrand';
import SingleBrand from './components/brands/SingleBrand/SingleBrand';
import RealEstate from './components/create/RealEstate';

import Mypost from './components/posts/Mypost/Mypost';
import SinglePost from './components/posts/SinglePost/SinglePost';
import SinglePreview from './components/posts/SinglePost/SinglePreview';
import CreatePostForm from './components/forms/CreatePost/CreatePost';
import CreateArticle from './components/forms/CreateArticle/CreateArticle';
import Categories from './components/search/categories';
import ViewJob from './components/jobs/ViewJob';
import BasicTabs from './components/layout/userMenu';
// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // console.log(localStorage.jwtToken)
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
// Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

function Products() {

    const location = useLocation();
    console.log(location);

    return (
    <div className="container">
        <h2>Products page</h2>
        <p>Pathname: {location.pathname}</p>
        <p>Search Parameter: {new URLSearchParams(location.search).get('name')}</p>
    </div>
    );
}

// function App(props) {
const App = (props) => {
  // const location = useLocation();
  return (
    <Provider store={store}>
      <Router>
      <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/post/:id" exact element={<SinglePreview />} />
          <Route path="/public/:id" element={<Profile />} />
          <Route path="/blog" element={<Blog />}></Route>
          // <Route path="/blogg" element={<Blog />}></Route>
          {<Route
            path="/preview/:id"
            element={
              <PrivateRoute>
                <SinglePreview />
              </PrivateRoute>
              }
          />}
            <Route path="/blog/:id" exact element={<SinglePreview />}></Route>
          {<Route
            path="/real-estate"
            element={
              <PrivateRoute>
                <RealEstate />
              </PrivateRoute>
            }
          />}
          {<Route
            path="/real-estate/:id"
            element={
              <PrivateRoute>
                <RealEstate />
              </PrivateRoute>
            }
          />}
          {<Route
            path="/brand/:id"
            element={
              <PrivateRoute>
                <SingleBrand />
              </PrivateRoute>
            }
          />}
          {<Route
            path="/createBrand"
            element={
              <PrivateRoute>
                <CreateBrand />
              </PrivateRoute>
            }
          />}
          {<Route
            path="/tryme"
            element={
              <PrivateRoute>
                <CreatePostForm />
              </PrivateRoute>
            }
          />}
          {<Route
            path="/tryme/:id"
            element={
              <PrivateRoute>
                <CreatePostForm />
              </PrivateRoute>
            }
          />}
          {<Route
            path="/mypost"
            element={
              <PrivateRoute>
                <Mypost />
              </PrivateRoute>
            }
          />}
          {<Route
            path="/mypost/:id"
            element={
              <PrivateRoute>
                <SinglePost />
              </PrivateRoute>
            }
          />}
          <Route
            path="/createProfile"
            element={
              <PrivateRoute>
                <CreateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            exact
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/edit/:id"
            exact
            element={
              <PrivateRoute>
                <CreateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/create"
            element={
              <PrivateRoute>
                <CreateArticle />
              </PrivateRoute>
            }
          />
          <Route
            path="/create/:id"
            element={
              <PrivateRoute>
                <CreateArticle />
              </PrivateRoute>
            }
          />
          <Route
            path="/job/:id"
            element={
              <PrivateRoute>
                <ViewJob />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit/:id"
            element={
              <PrivateRoute>
                <CreatePost />
              </PrivateRoute>
            }
          />
          {/*<Route>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
            </Route>*/}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
        </Routes>
        <Footer />
      </Router>
    </Provider>
  );
}

library.add(fas)
export default App;
