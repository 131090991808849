import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'

// class Footer extends Component {
const Footer = () => {

  /*constructor() {
    super();
    this.state = { showMessage: false }
  };

  _showMessage = (bool) => {
    this.setState({
      showMessage: bool
    });
  }*/

  // render() {
    return (
      <>
      <footer className="page-footer" style={{backgroundColor:'#e3bab3'}}>
          <div className="container" style={{marginTop:'0',marginBottom:'0',padding: '10px'}}>

          <div style={{textAlign:'center'}}>
          <a href='https://www.facebook.com/TimbrTech/' target="_blank" className='me-4 text-reset socialBtnAlt'>
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href='https://twitter.com/TimbrTech' target="_blank" className='me-4 text-reset socialBtnAlt'>
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href='https://www.instagram.com/timbrtech/' target="_blank" className='me-4 text-reset socialBtnAlt'>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href='' target="_blank" className='me-4 text-reset socialBtnAlt'>
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>

            {/*<div class="row">
              <div class="col l6 s12">
                <h5 class="white-text">Footer Content</h5>
                <p class="grey-text text-lighten-4">You can use rows and columns here to organize your footer content.</p>
              </div>
              <div class="col l4 offset-l2 s12">
                <h5 class="white-text">Links</h5>
                <ul>
                  <li><a class="grey-text text-lighten-3" href="#!">Link 1</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!">Link 2</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!">Link 3</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!">Link 4</a></li>
                </ul>
              </div>
            </div>*/}
          </div>
          <div className="footer-copyright">
            <div className="container" style={{textAlign:'center', marginTop: '0', marginBottom:'0'}}>
            © {new Date().getFullYear()} Copyright: <b>Timbr Technologies</b>
            </div>
          </div>
        </footer>
      </>
    );
  // }

}

/*const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(Footer);*/

export default Footer;
