import React from "react";
import { Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Login from "../../components/auth/Login";
// let navigate = useNavigate();
/*const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        // <Route path="about" render={() => <Redirect to="/login" />} />
        <Navigate to="/login" />
        // <Component {...Login} />
        // path="/login" element={<Login />}
        // <Route path="/login" element={<Login />} />
        // <Redirect to="/login" />
      )
    }
  />
);*/

const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
  return isAuthenticated ? children : <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(PrivateRoute);
