import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'

export const withRouter = WrappedComponent => props => {
  const location = useLocation();
  const params = useParams();
  const history = useNavigate();
  const pathname = location.pathname
  const match = { params: useParams(), path:pathname, navigate:useNavigate() };
  // etc... other react-router-dom v6 hooks

  return (
    <WrappedComponent
      {...props}
      match={match}
      // params={params}
      // etc...
    />
  );
};
