import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import Axios from "axios";

import { search } from "./utils";
// import Movies from "./Movies";

class Categories extends Component {

  constructor(props) {
      super(props)
      this.state = {
        movies: null,
        loading: false,
        value: "",
        plainArray: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
        objectArray: [
          { key: "Option 1", name: "Group 1" },
          { key: "Option 2", name: "Group 1" },
          { key: "Option 3", name: "Group 1" },
          { key: "Option 4", name: "Group 2" },
          { key: "Option 5", name: "Group 2" },
          { key: "Option 6", name: "Group 2" },
          { key: "Option 7", name: "Group 2" }
        ],
        selectedValues: [
          { key: "Option 1", cat: "Group 1" },
          { key: "Option 2", cat: "Group 1" }
        ]
      };
}

componentDidMount() {
    this.setState(pre => ({
        loading: true
    }))
    Promise.all([
        Axios.get('/api/categories/'),
    ]).then(data => {
      console.log(data)
        this.setState(pre => ({
            loading: false
        }))
        // this.setState({ ...this.state.posts, posts: data[0].data.posts });
        this.setState({objectArray:data[0].data.posts})
        // this.setState({ ...this.state.users, users: data[1].data.profile });
    })
        .catch(e => {
            this.setState(pre => ({
                loading: false
            }))
        })
}

  search = async val => {
    this.setState({ loading: true });
    const results = await search(
      // `https://api.themoviedb.org/3/search/movie?query=${val}&api_key=dbc0a6d62448554c27b6167ef7dabb1b`
      `/api/categories/${val}`
    );
    const movies = results;
    console.log(movies)
    if (results.posts && results.posts.length > 100) {
      console.log('yup')
    this.setState({objectArray:results.posts})
  }

    // this.setState({ movies, loading: false });
  };

  onChangeHandler = async e => {
    this.search(e.target.value);
    this.setState({ value: e.target.value });
  };

  get renderMovies() {
    let movies = <h1>There's no movies</h1>;
    /*if (this.state.movies) {
      movies = <Movies list={this.state.movies} />;
    }*/

    return movies;
  }

  render() {
    const { plainArray, objectArray, selectedValues } = this.state;
    return (
      <div class="container">
        {/*<input
          value={this.state.value}
          onChange={e => this.onChangeHandler(e)}
          placeholder="Type something to search"
        />
        {this.renderMovies}*/}
        <div>
        <h4 className="mt20" id="flat">
            1. Multiselect with flat array
          </h4>
          <Multiselect showArrow options={objectArray} displayValue="name" />
        </div>
      </div>
    );
  }
}

export default Categories;
