import Axios from 'axios';
import React, { Component, useRef } from 'react'
import { Link, Navigate, useNavigate } from "react-router-dom";
import Spinner from '../../Containers/Spinner/Spinner';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
// import "./style.scss";

import PropTypes from "prop-types";
import { compose } from 'redux';
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { withRouter } from "../../context/withRouter";

export class ViewJob extends Component {

  constructor(props) {
    super(props)
    this.state = {
     };
    // this.handleSubmit = this.handleSubmit.bind(this)
    // this.mySubmitHandler = this.mySubmitHandler.bind(this)
  }

  componentDidMount() {
    let id = this.props.match.params.id
    Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/job/"  + id).then(data => {
      console.log(data.data)
      this.setState(data.data)
      console.log(this.state)
    })
    .catch(e => {
      console.log(e)
        /*this.setState({
            isloading: false,
            error: {
                ...this.state.error, message: e.response.data.message,
                code: e.response.status
            }
        });*/
    })
  }

  render() {

    return (
      <div className="container">
      {
        this.state.cloudUrls ?
        this.state.cloudUrls.map((url, index) => (
          <div key={index}>{url.toString().split('.')[url.toString().split('.').length-1] === 'mp4' ? <video controls style={{width:'100%'}} key={index}><source src={url + '#t=3'} type="video/mp4"></source></video> : <img src={url} style={{width:'100%'}} key={index}></img>}</div>
          /*if (url.split('.')[url.split('.').length - 1] === 'mp4') {
            // <video></video>
          } else {
            // <img src={url} style={{width:'100%'}} key={index}></img>
          }*/
        ))
        /*this.state.cloudUrls.map((url,i) => {
          <p>hi</p>
          // <img src={url} alt="Italian Trulli"></img>
        })*/
        // <p>{this.state.cloudUrls[0]}</p>
        // <img src="pic_trulli.jpg" alt="Italian Trulli">
        :
        // null
        <p>Please wait while project renders.</p>
      }
      </div>
    );
  }

}

const mapStateToProps = state => ({
  auth: state.auth
});
export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(ViewJob);
