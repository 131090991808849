import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";

export class Spinner extends Component {
  render() {
    return (
      /*{<div className="text-center spinner">}*/
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
          <a href='https://www.facebook.com/TimbrTech/' target="_blank" className='me-4 text-reset'>
            <FontAwesomeIcon icon={faSpinner} />
          </a>
        </div>
      /*{</div>}*/
    )
  }
}

export default Spinner
