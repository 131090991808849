import Axios from 'axios'
import React, { Component } from 'react'
import ShowPost from './ShowPost/ShowPost'
import img1 from '../../assets/asset-1.png'
// import ShowUser from '../Users/ShowUsers/ShowUser'
import Spinner from '../../Containers/Spinner/Spinner';

import { compose } from 'redux';
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { withRouter } from "../../context/withRouter";

export class Posts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            posts: [],
            users: [],
            isloading: false
        }

    }

    componentDidMount() {
        this.setState(pre => ({
            isloading: true
        }))
        Promise.all([
            Axios.get('/blog/post/'),
            Axios.get('/blog/profile/profiles')
        ]).then(data => {
          console.log(data)
            this.setState(pre => ({
                isloading: false
            }))
            this.setState({ ...this.state.posts, posts: data[0].data.posts });
            this.setState({ ...this.state.users, users: data[1].data.profile });
        })
            .catch(e => {
                this.setState(pre => ({
                    isloading: false
                }))
            })
    }

    render() {
        /*let isLoading
        if (this.state.isloading) {
            isLoading = <Spinner />
        }*/
        let fetchedposts
        let allUsers
        if (this.state.posts) {
            fetchedposts = this.state.posts.map((post, index) => (
                <ShowPost key={index} {...post} {...index} />
            ))
        }
        /*if (this.state.users) {
            allUsers = this.state.users.map((user, index) => (
                <ShowUser key={index} {...user} {...index} />
            ))
        }*/
        return (
            <div>

                {/*<div className="container hero">*/}
                <div className="hero" style={{padding:'10vh 5vh',backgroundColor: '#f9f2f0', minWidth:'100%'}}>
                    <div className="row align-items-center text-center text-md-left" style={{paddingTop:'5vh'}}>
                        <div className="col-lg-4" style={{textAlign:'center'}}>
                            <h2 className="mb-3 display-3" style={{fontWeight:'800'}}>
                                Wood Chips
                    </h2>
                            <p style={{fontSize:'20px',lineHeight:'32px', width: '75%', margin: '0 auto'}}>
                                Musings from the Timbr ecosystem. Focused on marketing and technology with a spattering of sports & entertainment.
                    </p>
                        </div>
                        {/*<div className="col-lg-8">
                            <img src={img1} className="img-fluid" alt="img" style={{width:'100%'}} />
                        </div>*/}
                    </div>


                </div>
                <div className="container hero py-5">
                    <div className="row">
                        <div className="col-md-8 col-xs-12">
                            <div className="row">
                                {fetchedposts}
                            </div>
                        </div>

                        {/*<div className="col-md-4 col-xs-12 pl-4">
                            <h3 className="mb-4"> Popular Writers</h3>
                            <hr></hr>
                            {allUsers}
                        </div>*/}
                    </div>
                </div>

                <div className="container loading">
                    {/*{isLoading}*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(Posts);

// export default Posts
