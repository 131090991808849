import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import ToText from '../../../utils/ToText'
import './ShowPost.css'
import avtar from '../../../assets/avtar.jpeg'
import Spinner from '../../../Containers/Spinner/Spinner';
function ShowPost(props) {
  // console.log(props)

    const [post, setpost] = useState(props)
    const [loading, setLoading] = useState(false)
    const [errmsg, setErrorMsg] = useState()
    const [errcode, setErrorCode] = useState()
    const history = useNavigate()
    const pathname = "/"

    useEffect(() => {
      console.log(props)
        setLoading(true)
        Axios.get("/blog/profile/bycreator/" + props.creator).then(data => {
            setLoading(false)
            setpost({ ...props, user: data.data.profile })
        })
            .catch(e => {
                setLoading(false)
            })

    }, [props])

    return (<>
        {errcode ?
            <div className="container error container-short">
                <div className="mar-20">
                    <h5>Error Code - {errcode}</h5>
                    <h4>Error Message - {errmsg}</h4>
                </div>
            </div>
            : null}

        {loading ?
            <div className="container loading">
                <div className="mar-20">
                    <Spinner />
                </div>
            </div>
            : null}
            <div className="col s12 m6 l6 showblog">
            <div className="showblog_card card">
                <div className="showblog_card_image"
                    style={{ backgroundImage: `url(${props.image ||props.media || props.content[0].media})` }}>


                    <div className="show_auth_img">
                        {/*<Link to={"/public/" + post.user?.username} style={{ backgroundImage: `url(${post.user?.imagePath ? post.user.imagePath : avtar})` }}>{post.user?.username}</Link>*/}
                    </div>
                </div>
                <div className="card-body">
                    <h5 className="card-title pt-3">
                        {pathname === "/mypost" ?
                            <Link
                                to={'/mypost/' + props._id}
                                className="title"
                                style={{fontWeight:'bolder',color:'#000000'}}>
                                {props.title || props.content[0].heading}
                            </Link> :

                            <Link
                                to={'/post/' + props._id}
                                className="title"
                                style={{fontWeight:'bolder',color:'#000000'}}>
                                {props.title || props.content[0].heading}
                            </Link>
                        }

                    </h5>

                    <p className="showblog_content">
                        {/*{`${ToText(
                            // props.description.substring(0, 150) || props.content[0].body.substring(0, 150)
                        )}...`}*/}
                        {/*<span> <b>Read More</b></span>*/}
                        <div style={{padding: '15px 0px 25px 0'}}><a style={{fontWeight:'500', textTransform:'none'}} className="navbar-item main-btn btn waves-effect waves-light hoverable btn-flat black-text timbrBtn" href={'/post/' + props._id}>Read More</a></div>
                    </p>
                </div>
            </div>
            </div>
        {/*<div className="col-md-6 col-sm-6 col-xs-12 showblog mb-3">
            <div className="showblog_card card">
                <div className="showblog_card_image"
                    style={{ backgroundImage: `url(${props.content[0].media})` }}>


                    <div className="show_auth_img">*/}
                        {/*<Link to={"/public/" + post.user?.username} style={{ backgroundImage: `url(${post.user?.imagePath ? post.user.imagePath : avtar})` }}>{post.user?.username}</Link>*/}
                    {/*</div>
                </div>
                <div className="card-body">
                    <h5 className="card-title pt-3">
                        {pathname === "/mypost" ?
                            <Link
                                to={'/mypost/' + props._id}
                                className="title">
                                {props.content[0].heading}
                            </Link> :

                            <Link
                                to={'/post/' + props._id}
                                className="title">
                                {props.content[0].heading}
                            </Link>
                        }

                    </h5>

                    <p className="showblog_content">
                        {`${ToText(
                            props.content[0].body.substring(0, 80)
                        )}...`}<span> <b>Read More</b></span>
                    </p>
                </div>
            </div>
        </div>*/}
    </>
    )
}

export default ShowPost
