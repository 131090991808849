import React, { useState } from "react";
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <input
            type="text"
            value={name}
            onChange={(event) => onValUpdate(index, event)}
            name="name"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={email}
            onChange={(event) => onValUpdate(index, event)}
            name="email"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={profile}
            onChange={(event) => onValUpdate(index, event)}
            name="profile"
            className="form-control"
          />
        </td>
        <td>
          <button
            className="btn btn-dark"
            onClick={() => tableRowRemove(index)}
          >
            Delete Row
          </button>
        </td>
      </tr>
    );
  });
}

function ContentRows({ rows, tableRowRemove, onValUpdate, props }) {
  return rows.map((rowsData, index) => {
    const { content } = rowsData;
    return (
      <div key={index}>
      <div key={index} className="form-group">
          <label htmlFor="password">Description </label>
          <textarea
              type='text'
              name='content'
              rows="4"
              value={content}
              className={"form-control"}
              placeholder="Enter the description"
              required="required"
              onChange={(event) => onValUpdate(index, event)}
              // onChange={this.myChangeHandler}
          />

          {/*{this.state.errors.content.length > 0 &&
              <div className="mt-1"><span className='error text-danger'>{this.state.errors.content}</span></div>}*/}
      </div>

        <button
          className="btn btn-dark"
          onClick={() => tableRowRemove(index)}
        >
          Delete Row
        </button>

      </div>
    );
  });
}

function Table(props) {
  console.log(props)
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    const data = {
      content: ""
      /*name: "",
      email: "",
      profile: "",*/
    };
    initRow([...rows, data]);
    // console.log(rows)
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    console.log(event.target)
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (props.data.contentArray) {
    // rows.push({content:'me'})
    // [...rows, {content:'me'}]
    // console.log(rows)
    // rows[0] = {content:'me'}
    // initRow((rows) => [{content:'me'}])
    // const [rows, initRow] = useState([{content:'me'}]);
    // const { name, value } = event.target;
    /*const data = {
      content:"",
    };
    const name = 'content'
    const value = props.data.contentArray[0]
    // const data = [...rows];
    data[0][name] = value;
    initRow(data);*/
    // console.log(props.data.contentArray[0])
    // var obj = {target:props.data.contentArray[0]}
    // console.log(obj)
    // rows = [props.data.contentArray[0]]
    // onValUpdate(0,obj)
  }
  return (
    <>
      <h2 className="text-center">Create Content</h2>
      <button className="btn btn-danger" onClick={addRowTable}>
        Insert Row
      </button>
      <ContentRows
        rows={rows}
        tableRowRemove={tableRowRemove}
        onValUpdate={onValUpdate}
      />
      {/*<table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Profile</th>
            <th>
              <button className="btn btn-danger" onClick={addRowTable}>
                Insert Row
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <ContentRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
          />
        </tbody>
      </table>*/}
    </>
  );
}

export default Table;
