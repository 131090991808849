import Axios from 'axios';
import React, { Component } from 'react'
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ImageUpload from '../../../Containers/ImageUpload/ImageUpload'
import Spinner from '../../../Containers/Spinner/Spinner'
import ImageUploader from '../../../components/imageUpload/imageUpload'
import PropTypes from "prop-types";
import { compose } from 'redux';
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { withRouter } from "../../../context/withRouter";

export class CreateBrand extends Component {
    constructor(props) {
        super(props)

        this.state = {
          file:'',
          description:'',
            Post: {
                id: '',
                name: '',
                // bio: '',
                logo: '',
                categories: [],
                created: '',
                color: '#ff0000'
            },
            logo: '',
            error: {
                message: '',
                code: ''
            },
            isloading: false,

            haserror: false,
            errors: {
                username: '',
                bio: '',
                logo: '',
            },
            objectArray: []
        }
        this.mySubmitHandler = this.mySubmitHandler.bind(this);
        this.myChangeHandler = this.myChangeHandler.bind(this);
        this.submit = this.submit.bind(this);
        this.setFile = this.setFile.bind(this);
    }

    componentDidMount() {
      // console.log(this)
        let path = this.props.match.path
        let id = this.props.match.params.id
        this.setState(pre => ({
            isloading: true
        }))
        Axios.get('/api/categories/').then(data => {
          // console.log(data)
            this.setState({
              objectArray:data.data.posts,
              isloading: false,
            })
        })
            .catch(e => {
                this.setState({
                    isloading: false,
                    error: {
                        ...this.state.error, message: e.response.data.message,
                        code: e.response.status
                    }
                });
            })

        if (path === `/profile/edit/${id}`) {
            this.setState(pre => ({
                isloading: true
            }))
            Axios.get("blog/profile/viewprofile").then(data => {
              console.log(data)
                let post = data.data.profile
                this.setState({
                    isloading: false,
                    Post: { ...this.state.Post, id: post._id, username: post.username, bio: post.bio, logo: post.logo, categories: post.categories }
                });
            })
                .catch(e => {
                    this.setState({
                        isloading: false,
                        error: {
                            ...this.state.error, message: e.response.data.message,
                            code: e.response.status
                        }
                    });
                })
        }
    }

    imageHandler = (id, value, isValid) => {
      console.log(id)
      console.log(value)
        /*this.setState({ Post: { ...this.state.Post, [id]: value } }, () => {

        });*/
        let { Post } = this.state;
        Post.logo = value

        // this.setState({logo:value})

      console.log(this.state)

    }

    onChangeHandler = (event) => {
      console.log(event)
      let { categories } = this.state.Post;
      console.log(categories)
      /*event.map((x, index) => {
        if (x.id === )
      }*/
      event.map(x=> categories.find(y=> y.id==x.id) ? null : categories.push({id:x.id,name:x.name}) )
      // categories.push({id:event[0].id,name:event[0].name})

      // console.log(categories)
      // this.setState({...this.state.Post})
      // let cats = [...this.state.Post.categories];
      // console.log(cats)
      /*cats.push({id:event[0].id,name:event[0].name})
      console.log(cats)*/
      // this.setState({ ...this.state.Post.categories, categories });
      console.log(this.state)
    }

    onRemoveHandler = (event) => {
      console.log(event)
      let { Post } = this.state;
      Post.categories = event
      // this.setState({ ...this.state.Post, categories:event})
      console.log(this.state)
    }

    setFile = (event) => {
      console.log(event)
      // let { file } = this.state;
      // file = event.target.files[0]
      this.setState({file:event.target.files[0]})
      let { description } = this.state;
      description = event.target.value
      console.log(this.state)
    }

    changeColor = (event) => {
      console.log(event.target.value)
      // let {color} = this.state.Post
      // color = event.target.value
      this.setState({ Post: { ...this.state.Post, color: event.target.value } }, () => {

      });
      // this.setState({...this.state.Post, color:event.target.value})
    }

    myChangeHandler = (event) => {
        let nam = event.target?.name;
        let val = event.target?.value;
        let errors = this.state.errors;
        const { name, value } = event.target;
        switch (name) {

            case 'username':
                if (value.length > 0) {
                    errors.username =
                        value.length < 6
                            ? 'Title   must be 5 characters long!'
                            : '';
                }

                if (value.length === 0) {
                    errors.username =
                        value.length === 0
                            ? 'username is required!'
                            : '';
                }
                break;

            case 'logo':
                if (value.length === 0) {
                    errors.logo =
                        value.length === 0
                            ? 'Image is required!'
                            : '';
                }
                break;
            default:
                break;
        }

        this.setState({ ...errors, Post: { ...this.state.Post, [nam]: val } }, () => {

        });
    }

    submit(event) {
      this.setState(pre => ({
          isloading: true
      }))
    event.preventDefault()
    console.log(this.state)

    // Send the file and description to the server
    const formData = new FormData()
    formData.append("image", this.state.file)
    formData.append("description", this.state.description)
    // const result = await axios.post('/api/images', formData, { headers: {'Content-Type': 'multipart/form-data'}})
    Axios.post("/blog/profile/user", formData)
    .then(data => {
      console.log(data)
    }).catch(e => {
    // console.log(result.data)
  })
  }

    mySubmitHandler(e) {
        this.setState(pre => ({
            isloading: true
        }))
        e.preventDefault()
        const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        xhr.onreadystatechange = (e) => {
          if (xhr.readyState == 4 && xhr.status == 200) {
            const response = JSON.parse(xhr.responseText);

            // setImage(response.secure_url);
            console.log(response.secure_url);
            let formData;
            formData = new FormData();
            // formData.append('id', this.state.Post.id);
            formData.append('name', this.state.Post.username);
            // this.state.Post.categories.map(x=>formData.append('categories[]',JSON.stringify(x)))
            formData.append('categories', JSON.stringify(this.state.Post.categories));
            formData.append('logo', response.secure_url);
            formData.append('color', this.state.Post.color);
            formData.append('creator', this.props.auth.user.id);
            console.log(this.state.Post.categories)
            console.log(formData.get('creator'))
            // Axios.post("/brands/brands/create", formData)
            Axios.post("https://zany-pink-dolphin-veil.cyclic.app/api/brand/create", formData)
            // Axios({method:'post',url:'/blog/profile/create',data:formData,headers: { "content-type": "multipart/form-data" }})
            .then(data => {
              console.log(data.data)
                this.setState(pre => ({
                    isloading: true
                }))
                // let profile = data.data.post._doc.username;
                /*let profile = data.data.profile.username;
                localStorage.setItem(
                    'profileData',
                    JSON.stringify({
                        "username": profile
                    }))*/
                // this.props.history.push('/')
                // this.props.match.navigate(0);
                this.props.match.navigate('/brand/' + data.data.insertedId);
            }).catch(e => {
              console.log(e)
                this.setState({
                    isloading: false,
                    error: {
                        ...this.state.error, message: e.response.data.message,
                        code: e.response.status
                    }
                });
            })

          }
        };

        fd.append(
          "upload_preset",
          process.env.REACT_APP_CLOUD_UNSIGNED
        );
        fd.append("tags", "browser_upload");
        fd.append("file", this.state.Post.logo);
        xhr.send(fd);


        /*let path = this.props.match.path
        let id = this.props.match.params.id

        e.preventDefault()
        console.log(e)
        let formData;
        if (typeof (this.state.Post.logo) === "object") {
          // upload image and then use uel for image
            // const form = document.getElementById('form');
            // formData = new FormData(form);
            formData = new FormData();
            formData.append('id', this.state.Post.id);
            formData.append('username', this.state.Post.username);
            formData.append('bio', this.state.Post.bio);
            formData.append('categories', this.state.Post.categories);
            formData.append('image', this.state.Post.logo);
            // formData.me = 'you'
            // console.log(formData)

        }
        else {
            formData = {
                // "id": this.state.Post.id,
                'username': this.state.Post.username,
                'bio': this.state.Post.bio,
                'categories': this.state.Post.categories,
                'image': this.state.Post.logo,

            }
            // console.log(formData)
        }
        for (var key of formData.entries()) {
        console.log(key[0] + ', ' + key[1]);
    }

        if (path === `/profile/edit/${id}`) {
            Axios.put("blog/profile/edit/" + id, formData).then(data => {
                this.setState(pre => ({
                    isloading: false
                }))
                // this.props.history.push('/profile')
                this.props.match.navigate(0);
            })
                .catch(e => {
                    this.setState({
                        isloading: false,
                        error: {
                            ...this.state.error, message: e.response.data.message,
                            code: e.response.status
                        }
                    });
                })
        }
        else {
            Axios.post("/blog/profile/create", formData)
            // Axios({method:'post',url:'/blog/profile/create',data:formData,headers: { "content-type": "multipart/form-data" }})
            .then(data => {
              console.log(data.data)
                this.setState(pre => ({
                    isloading: true
                }))
                // let profile = data.data.post._doc.username;
                let profile = data.data.profile.username;
                localStorage.setItem(
                    'profileData',
                    JSON.stringify({
                        "username": profile
                    }))
                // this.props.history.push('/')
                this.props.match.navigate(0);
            }).catch(e => {
              console.log(e)
                this.setState({
                    isloading: false,
                    error: {
                        ...this.state.error, message: e.response.data.message,
                        code: e.response.status
                    }
                });
            })
        }
        this.setState({
            Post: { ...this.state.Post, username: '', bio: '', logo: '', categories: [] }
        });*/
    }
    render() {
      const { objectArray } = this.state;
      const { categories } = this.state.Post;
      // console.log(categories)

        let isLoading
        let iserror

        if (this.state.isloading) {
            isLoading = (
                <>
                    <div className="container loading">
                        <div className="mar-20">
                            <Spinner />
                        </div>
                    </div>
                </>
            )
        }

        if (this.state.error.code) {
            iserror = (
                <>
                    <div className="container error container-short">
                        <div className="mar-20">
                            <h5>Error Code - {this.state.error.code}</h5>
                            <h4>Error Message - {this.state.error.message}</h4>
                        </div>
                    </div>
                </>
            )
        }


        return (<>
            {isLoading}
            {iserror}
            <div className="container container-short">
                <form id="form" onSubmit={this.mySubmitHandler} className="pt-4" encType='multipart/form-data'>
                    <h3 className="text-center mb-3">Create Brand</h3>
                    <div className="form-group">
                        <label htmlFor="username">Name </label>
                        <input
                            type='text'
                            name='username'
                            value={this.state.Post.username}
                            className={"form-control " + (this.state.errors.username ? 'is-invalid' : '')}
                            placeholder="Enter the Brand name"
                            required
                            onChange={this.myChangeHandler}
                        />

                        {this.state.errors.username.length > 0 &&
                            <div className="mt-1"><span className='error text-danger'>{this.state.errors.username}</span></div>}

                    </div>

                    {/*<div className="form-group">
                        <label htmlFor="password">Bio </label>
                        <input
                            type='text'
                            name='bio'
                            rows="4"
                            value={this.state.Post.bio}
                            className={"form-control " + (this.state.errors.bio ? 'is-invalid' : '')}
                            placeholder="Enter the  description"
                            required="required"
                            onChange={this.myChangeHandler}
                        />

                        {this.state.errors.bio.length > 0 &&
                            <div className="mt-1"><span className='error text-danger'>{this.state.errors.bio}</span></div>}
                    </div>*/}
                    <div className="form-group">
                      <label>Categories </label>
                      <Multiselect showArrow options={objectArray} placeholder="Select categories" onSelect={this.onChangeHandler} onRemove={this.onRemoveHandler} selectedValues={categories} displayValue="name" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Image </label>
                        <ImageUpload
                            id="logo"
                            name="logo"
                            onInput={this.imageHandler}
                            value={this.state.Post.logo}
                            errorText="Please provide a logo."
                        />
                        {this.state.errors.logo.length > 0 &&
                            <div className="mt-1"><span className='error text-danger'>{this.state.errors.logo}</span></div>}
                    </div>
                    <div className="form-group" style={{padding: '20px 0'}}>
                      <label htmlFor="color">Color </label>
                      <input style={{width:'100%'}} type="color" id="favcolor" name="favcolor" value={this.state.Post.color} onChange={this.changeColor}/>
                    </div>
                    <div className="form-group">
                        <button style={{ marginRight: '15px' }}
                            type='submit'
                            className="btn btn-primary"
                            disabled={this.state.Post.username && this.state.Post.logo.name
                                ? '' : 'disabled'}
                        >
                            Create Profile
                        </button>
                    </div>
                </form>

                {/*<form onSubmit={this.submit} encType="multipart/form-data">
                  <input
                    filename={this.state.file}
                    // onChange={e => setFile(e.target.files[0])}
                    onChange={this.setFile}
                    type="file"
                    accept="image/*"
                  ></input>
                  <input
                    // onChange={e => setDescription(e.target.value)}
                    type="text"
                  ></input>
                  <button type="submit">Submit</button>
                </form>*/}

                {/*<ImageUploader/>*/}

            </div>
        </>
        )
    }
}

/*CreateProfile.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};*/
const mapStateToProps = state => ({
  auth: state.auth
});
export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(CreateBrand);
