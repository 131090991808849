import Axios from 'axios';
import React, { Component } from 'react'
import { Link, Navigate, useNavigate } from "react-router-dom";
import ImageUpload from '../../../Containers/ImageUpload/ImageUpload'
import Spinner from '../../../Containers/Spinner/Spinner';
import StaffTable from './AddDeleteRow';
import Table from './table';

import PropTypes from "prop-types";
import { compose } from 'redux';
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { withRouter } from "../../../context/withRouter";

export class CreatePostForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      // title:"",
      formValues: [{ heading: "", subheading : "", body:"", media:"" }]
     };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.mySubmitHandler = this.mySubmitHandler.bind(this)
  }

  myChangeHandler(e) {
    // console.log(e.target.value)
    let title = this.state.title
    title = e.target.value
    this.setState({ title });
    // console.log(this.state)
  }

  componentDidMount() {
      // console.log(this.props)
      let path = this.props.match.path
      let id = this.props.match.params.id
      const storedData = JSON.parse(localStorage.getItem('profileData'));
      // console.log(storedData)
      if (!storedData && path == "/create") {
        this.props.match.navigate("/createProfile")
              // this.props.history.push("/createProfile")
      }
      if (path === `/tryme/${id}`) {
        // console.log('hi')
          this.setState(pre => ({
              isloading: true
          }))

          Axios.get("blog/post/" + id).then(data => {
              let post = data.data
              // console.log(post)
              /*if (data.data.contentArray) {
                console.log(data.data.contentArray)
                  var fetchedposts = data.data.contentArray.map((post, index) => (
                      <CreatePostForm key={index} {...post} {...index} />
                  ))
                  console.log(fetchedposts)
              }*/
              this.setState({
                  isloading: false,
                  formValues: post.content
              });
          })
              .catch(e => {
                  this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });
              })
      }
      console.log(this.state)
  }

  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  addFormFields() {
    // console.log(this.state)
    this.setState(({
      formValues: [...this.state.formValues, { heading: "", subheading : "", body:"" }]
    }))
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  mySubmitHandler(e) {
      this.setState(pre => ({
          isloading: true
      }))
      let path = this.props.match.path
      let id = this.props.match.params.id
      const { navigate } = this.props.match;
      let date = new Date()
      e.preventDefault()
      let formData;
      /*if (typeof (this.state.Post.imagePath) === "object") {
          formData = new FormData();
          formData.append('id', this.state.Post.id);
          formData.append('title', this.state.Post.title);
          formData.append('content', this.state.Post.content);
          formData.append('image', this.state.Post.imagePath, this.state.Post.title);
          formData.append('postDate', date.toString());
      }
      else {*/
          formData = {
              // "id": "63ac5dc2f7c3ee94456a2857",
              "content": this.state.formValues,
              'postDate': date.toString(),
              // "id": this.state.Post.id,
              // 'title': this.state.Post.title,
              // 'content': this.state.Post.content,
              // 'image': this.state.Post.imagePath,
              // 'postDate': date.toString(),
              // 'creator': '63ac5dc2f7c3ee94456a2857'
          }
          // console.log(formData)
      // }

      if (path === `/tryme/${id}`) {
          Axios.put("/blog/post/" + id, formData).then(data => {
              this.setState(pre => ({
                  isloading: false
              }))
              // this.props.history.push('/')
              this.props.match.navigate(0);
          })
              .catch(e => {
                  this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });
              })
      }
      else {
          Axios.post("/blog/post", formData).then(data => {
              this.setState(pre => ({
                  isloading: false
              }))
              navigate(0);
              // this.props.history.push('/')
          })
              .catch(e => {
                // console.log(e)
                  this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });
              })
      }
      this.setState({
          formValues: formData.content
      });
  }

  render() {

    return (
      <div className="container">
        <form  onSubmit={this.mySubmitHandler}>

          {/*{this.state.formValues.map((element, index) => (
            <div className="form-inline" key={index}>
              <label>Heading</label>
              <input type="text" name="heading" value={element.heading || ""} onChange={e => this.handleChange(index, e)} />
              <label>Subheading</label>
              <input type="text" name="subheading" value={element.subheading || ""} onChange={e => this.handleChange(index, e)} />
              <label>Body</label>
              <input type="text" rows="4" name="body" value={element.body || ""} onChange={e => this.handleChange(index, e)} />
              {
                index ?
                  <button type="button"  className="button remove" onClick={() => this.removeFormFields(index)}>Remove</button>
                : null
              }
            </div>
          ))}*/}
          {this.state.formValues.map((element, index) => (
            <div key={index} style={{padding:'15px',background:'#e3e3e3',marginTop: '25px'}}>

            <div className="form-group">
                <label htmlFor="title">Title </label>
                <input
                    type='text'
                    name='heading'
                    value={element.heading || ""}
                    className="form-control"
                    placeholder="Enter the title"
                    required
                    onChange={e => this.handleChange(index, e)}
                />

                {/*{this.state.errors.title.length > 0 &&
                    <div className="mt-1"><span className='error text-danger'>{this.state.errors.title}</span></div>}*/}

            </div>

            <div className="form-group">
                <label htmlFor="description">Description </label>
                <textarea
                    type='text'
                    name='body'
                    rows="4"
                    value={element.body || ""}
                    className={"form-control materialize-textarea"}
                    placeholder="Enter the description"
                    required="required"
                    onChange={e => this.handleChange(index, e)}
                />

                {/*{this.state.errors.content.length > 0 &&
                    <div className="mt-1"><span className='error text-danger'>{this.state.errors.content}</span></div>}*/}
            </div>

            <div className="form-group">
                <label htmlFor="title">Media </label>
                <input
                    type='text'
                    name='media'
                    value={element.media || ""}
                    className="form-control"
                    placeholder="Enter url to media"
                    required
                    onChange={e => this.handleChange(index, e)}
                />

                {/*{this.state.errors.title.length > 0 &&
                    <div className="mt-1"><span className='error text-danger'>{this.state.errors.title}</span></div>}*/}

            </div>

            {
              index ?
                <button type="button"  className="button remove btn btn-primary" onClick={() => this.removeFormFields(index)}>Remove</button>
              : null
            }

            </div>

          ))}
          {/*<div className="button-section">
              <button className="button add" type="button" onClick={() => this.addFormFields()}>Add</button>
              <button className="button submit" type="submit">Submit</button>
          </div>*/}
          <div className="form-group" style={{paddingTop:'15px'}}>
              <button style={{ marginRight: '15px' }}
                  type='button'
                  className="btn btn-primary"
                  onClick={() => this.addFormFields()}
                  disabled={this.state.formValues
                      ? '' : 'disabled'}
              >
                  Add
              </button>
              <button style={{ marginRight: '15px' }}
                  type='submit'
                  className="btn btn-primary"
                  disabled={this.state.formValues
                      ? '' : 'disabled'}
              >
                  Submit
              </button>
          </div>
      </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(CreatePostForm);
