import Axios from 'axios'
import React, { Component } from 'react'
// import './SinglePost.css'
import avtar from '../../../assets/avtar.jpeg'
import { Link } from 'react-router-dom'
import moment from "moment";
import Spinner from '../../../Containers/Spinner/Spinner'
import Modal from '../../../Containers/Modal/Modal'

import { compose } from 'redux';
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { withRouter } from "../../../context/withRouter";

export class SinglePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            singlePost: {},
            contentArray: [],
            error: {
                message: '',
                code: ''
            },
            isloading: false,
            show: false
        }

    }

    componentDidMount() {
      console.log('hi')
        this.setState(pre => ({
            isloading: true
        }))
        let id = this.props.match.params.id
        // https://zany-pink-dolphin-veil.cyclic.app/api/contents/published/creator/63a635f60777d44e4a074ead
        Axios.get('https://zany-pink-dolphin-veil.cyclic.app/api/content/' + id).then(res => {
            this.setState({ ...this.state.singlePost, singlePost: res.data[0], contentArray:res.data[0].summary, isloading: false });
            console.log(res)
            // return Axios.get("/blog/profile/bycreator/" + res.data.creator)
        })/*.then(data => {
            this.setState({ ...this.state.singlePost, user: data.data.profile, isloading: false });
            // console.log(this.state)
        })*/.catch(e => {
        console.log(e)
                this.setState({
                    isloading: false,
                    error: {
                        ...this.state.error, message: e.response.data.message,
                        code: e.response.status
                    }
                });
            })

    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };


    deletePost=(id)=>{
        this.setState(pre => ({
            isloading: true
        }))
       Axios.delete("/blog/posts/"+id).then(data=>{
        this.setState(pre => ({
            isloading: false
        }))
        this.props.history.push('/mypost')
       })
       .catch(e=>{
        this.setState({
            isloading: false,
            error: {
                ...this.state.error, message: e.response.data.message,
                code: e.response.status
            }
        });
       })
    }

    render() {
        let isLoading
        let iserror

        if (this.state.isloading) {
            isLoading = (
                <>
                    <div className="container loading">
                        <div className="mar-20">
                            <Spinner />
                        </div>
                    </div>
                </>
            )
        }

        if (this.state.error.code) {
            iserror = (
                <>
                    <div className="container error container-short">
                        <div className="mar-20">
                            <h5>Error Code - {this.state.error.code}</h5>
                            <h4>Error Message - {this.state.error.message}</h4>
                        </div>
                    </div>
                </>
            )
        }

        let post
        let post1 = this.state.singlePost
        let user = this.state.user
        // console.log(user)
        // console.log(this.state.singlePost.content)
        // if (this.state.singlePost.content === Array) {
          // console.log(this.state)

            post = (<>
                <div className="singlePost_content pt-3">
                    <img src={post1.imagePath} className="img-fluid" alt="prof" style={{width: '100%', height: '300px', objectFit: 'cover'}} />
                    <h2>{post1.title}</h2>
                    <div className="SingleBlog_avatar">
                        <Link to={"/public/" + user?.username} rel="noopener noreferrer" >
                            {/*<img src={user?.imagePath ? user.imagePath : avtar} alt="img" width="75" height="75" />*/}
                        </Link>

                        <Link to={"/public/" + user?.username} rel="noopener noreferrer" >
                            <p>{user?.username}</p>
                        </Link>
                        <p>{moment(post1.postDate).format("MMM DD, YYYY hh:mm")}</p>
                    </div>
                    <div className="text pt-3">
                        {/*<p>{post1.content}</p>*/}
                    </div>
                    {/*<div>
                    {post1.content.map((post, index) => (
                      <p>{post}</p>
                    ))}
                    </div>*/}
                </div>
            </>
            )
        /*} else {
          console.log('hi')
          post = (<>
          </>)
        }*/
        return (<>
            {isLoading}
            {iserror}
            <div className="container py-4 SingleBlog " style={{}}>
                <div className="row">
                    {this.props.match.path === `/mypost/${this.props.match.params.id}` &&
                        <div className="col-md-3 col-xs-12 ">
                            <div className="list-group">
                                <Link to={"/edit/" + post1._id} className="list-group-item list-group-item-action">Edit</Link>
                                <button type="button"  onClick={this.showModal} className="list-group-item list-group-item-action">Delete</button>
                            </div>
                        </div>}
                    <div className="col-md-9 col-xs-12 main">
                        <Modal show={this.state.show} handleClose={this.hideModal}>
                            <div className="modal-header">
                            <h2>{this.state.singlePost.title}</h2>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.hideModal}>&times;</button>

                            </div>
                            <div className="modal-body">
                                Confirm delete
                           </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={()=>this.deletePost(post1._id)}>Delete </button>
                                <button type="button" className="btn btn-outline-dark" onClick={this.hideModal} data-dismiss="modal">Close</button>
                            </div>
                        </Modal>
                        {/*{post}*/}
                        <img src={this.state.singlePost.media || this.state.singlePost.image} className="img-fluid" alt="prof" style={{width: '100%', objectFit: 'cover'}} />
                        <h3 style={{fontWeight:'Bolder'}}>{this.state.singlePost?.title}</h3>
                        <div className="SingleBlog_avatar">
                            {/*<Link to={"/public/" + this.state.user?.username} rel="noopener noreferrer" >
                                <img src={this.state.user?.imagePath ? this.state.user.imagePath : avtar} alt="img" width="75" height="75" />
                            </Link>*/}

                            {this.state.singlePost.source ? <p>
                            {'From '}
                              {/*<Link to={this.state.singlePost?.url} rel="noopener noreferrer" >
                                  {this.state.singlePost?.source}
                              </Link>*/}
                              <a style={{fontWeight:'500', textTransform:'none'}} className="" target="_blank" href={this.state.singlePost?.url}>{this.state.singlePost?.source}</a>
                              {' on '}
                              {moment(this.state.singlePost?.created).format("MMM DD, YYYY")}
                            </p> : <p>{moment(this.state.singlePost?.created).format("MMM DD, YYYY")}</p>}
                        </div>
                        <p style={{fontSize:'20px',lineHeight:'32px'}}>{this.state.singlePost?.description}</p>
                        {this.state.contentArray.map((element, index) => (
                          <div key={index} style={{padding: '50px 0',}}>
                          {element.media ? <img src={element.media} className="img-fluid" alt="prof" style={{width: '100%', objectFit: 'cover'}} /> : null}
                          <h4 style={{color:'#e3bab3', fontWeight:'Bolder'}}>{element.heading}</h4>
                          {element.subheading
                          ? <h6>{element.subheading}</h6>
                          : null}
                          <p style={{fontSize:'20px',lineHeight:'32px', paddingBottom: '50px'}}>{element.body}</p>
                          <hr className="solid"></hr>
                          </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
        )
    }
}

const mapStateToProps = (state,props) => ({
  auth: state.auth,
});

export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(SinglePreview);

// export default SinglePost
