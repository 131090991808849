import React, { Component, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import logo from '../../assets/timbr-logo.png'
/*function mobileMenu() {
  console.log('go')
  // unauthed-ul
}*/
/*function goTime(){
  if (this.props.auth.isAuthenticated) {
    return <h1>Welcome back!</h1>;;
    // console.log(this.props.auth)
    // this.props.history.push("/dashboard");
  } else {
    return <p>hi</p>
  }
}*/

class Navbar extends Component {
  mobileMenu() {
    if (document.getElementsByClassName("unauthed-ul")[0].style.display === "none") {
      document.getElementsByClassName("unauthed-ul")[0].style.display = "block"
    } else {
      document.getElementsByClassName("unauthed-ul")[0].style.display = "none"
    }
    // console.log(document.getElementsByClassName("unauthed-ul")[0].style.display)
    // console.log(document.getElementsByClassName("unauthed-ul")[0].style.display = "none")// .style.display = "none";
    // unauthed-ul
  }

  constructor() {
    super();
    this.state = { showMessage: false }
  }

  _showMessage = (bool) => {
    this.setState({
      showMessage: bool
    });
  }

  /*componentDidMount() {
      // If logged in and user navigates to Login page, should redirect them to dashboard
      if (this.props.auth.isAuthenticated) {
        var button = <h1>Welcome back!</h1>;
        // return <h1>Welcome back!</h1>;;
        // console.log(this.props.auth)
        // this.props.history.push("/dashboard");
      } else {
        // return <p>hi</p>
      }
    }*/


  render() {
    if (this.props.auth.isAuthenticated) {
      var button = "Sign Out";
    } else {
      var button = "Sign In";
    }
    // console.log(this.props.auth)
    // console.log(this.props.auth.isAuthenticated)
    // const { user } = this.props.auth;
    return (
      <header className="navbar navbar-fixed">
        <div className="navbar-left" style={{display: 'flex', margin: '0px auto'}}>
          {/*<img src={logo} alt="bug" height={35} />*/}
          <div className="show_auth_img" style={{transform:'translateY(0)',marginLeft:'0', position: 'inherit'}}>
              <Link to={"/"} style={{ backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', padding: '15px', backgroundSize: '50%', backgroundColor: '#e3bab3' }}></Link>
          </div>
          <a aria-current="page" style={{margin: 'auto 0', lineHeight: '100%', textAlign: 'center', paddingLeft:'5px'}} className="navbar-item nav-active navbarFont" href="/">Timbr</a>
        </div>
        <div className="navbar-right unauthenticated">
          <div className="unauthed-large">
            <a className="navbar-item" rel="noopener noreferrer" href="/dashboard">Dashboard</a>
            {/*<a className="navbar-item" href="/register">Register</a>*/}
            <a className="navbar-item main-btn btn btn-large waves-effect waves-light hoverable btn-flat white-text accent-3 timbrBtn" href="/login">{button}</a>
          </div>
          <div className="dropdown" onClick={this.mobileMenu}>
          {/*<button onClick={this._showMessage.bind(null, true)}>show</button>
          <button onClick={this._showMessage.bind(null, false)}>hide</button>*/}
            <div className="navbar-item unauthed-menu">
              <svg width="24px" height="24px" viewBox="0 0 24 24">
                <g id="icon/overflow">
                <g id="icons8-menu_vertical">
                  <path d="M1,0 C0.448,0 0,0.448 0,1 L0,3 C0,3.552 0.448,4 1,4 L3,4 C3.552,4 4,3.552 4,3 L4,1 C4,0.448 3.552,0 3,0 L1,0 Z M1,7 C0.448,7 0,7.448 0,8 L0,10 C0,10.552 0.448,11 1,11 L3,11 C3.552,11 4,10.552 4,10 L4,8 C4,7.448 3.552,7 3,7 L1,7 Z M1,14 C0.448,14 0,14.448 0,15 L0,17 C0,17.552 0.448,18 1,18 L3,18 C3.552,18 4,17.552 4,17 L4,15 C4,14.448 3.552,14 3,14 L1,14 Z" id="Shape">
                  </path></g></g></svg></div>
                  <ul className="unauthed-ul" style={{display:"none"}}><a rel="noopener noreferrer" href="https://github.com/rishipr/feeder-react-feedback" target="_blank"><li><svg height="24" viewBox="0 0 24 24" width="24" className="dropdown-icon"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm0 14H5V8h14v10z"></path></svg>Docs</li></a><a href="/register"><li><svg height="24" viewBox="0 0 24 24" width="24" className="dropdown-icon"><path d="M0 0h24v24H0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path></svg>Register</li></a><a href="/login"><li><svg height="24" viewBox="0 0 24 24" width="24" className="dropdown-icon"><g><rect fill="none" height="24" width="24"></rect></g><g><path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"></path></g></svg> Sign In</li></a></ul>
                  </div>
                  </div>
      </header>

      /*<div className="navbar navbar-fixed">
        <nav className="z-depth-0">
          <div className="nav-wrapper white">
            <Link
              to="/"
              style={{
                fontFamily: "monospace",
                // borderBottom: "1px solid rgba(110,120,152,.25)"
              }}
              className="col s5 brand-logo center black-text"
            >
              <i className="material-icons">code</i>
              MERN
            </Link>
          </div>
        </nav>
      </div>*/
    );
  }
}
Navbar.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(Navbar);

// export default Navbar;
