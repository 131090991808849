import Axios from 'axios'
import React, { Component, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Spinner from '../../Containers/Spinner/Spinner';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "../../context/withRouter";

export class RealEstate extends Component {
  // console.log(props)
  constructor(props) {
      super(props)

      this.state = {
          location: {},
          listing: {},
          job: {},
          error: {
              message: '',
              code: ''
          },
          isloading: true,
          show: false,
          errors: {
              username: '',
              bio: '',
              logo: '',
          },
      }

      this.mySubmitHandler = this.mySubmitHandler.bind(this);
      this.myChangeHandler = this.myChangeHandler.bind(this);

  }

  componentDidMount() {

      this.setState(pre => ({
          isloading: true
      }))
      if (this.props.match.params.id) {
        console.log(this.props.match.params.id)
        let id = this.props.match.params.id
        Axios.get('/api/create/real-estate/' + id).then(res => {
            this.setState({ ...this.state, listing: res.data.profile, isloading: true });
            console.log(this.state)
        }).catch(e => {
                this.setState({
                    isloading: false,
                    error: {
                        ...this.state.error, message: e.response.data.message,
                        code: e.response.status
                    }
                });
            })
      }
    }

  myChangeHandler = (event) => {
    console.log(encodeURIComponent(event.target.value))
    Axios.get('/api/create/real-estate/quick/' + encodeURIComponent(event.target.value)).then(res => {
        this.setState({ ...this.state, listing: res.data.article, isloading: false });
        // this.setState({listing:res.data.article})
        console.log(res.data)
        console.log(this.state)
    }).catch(e => {
            this.setState({
                isloading: false,
                error: {
                    ...this.state.error, message: e.response.data.message,
                    code: e.response.status
                }
            });
        })
  }

  async mySubmitHandler(e) {
    this.setState(pre => ({
        isloading: true
    }))
    e.preventDefault()
    console.log(e)
    console.log(this.state)
    var theJob = await Axios.post("/api/create/job", this.state.listing)
    console.log(theJob)
    // var jobStatus = ''
    while (!theJob.data.profile.jobs || theJob.data.profile.jobs && theJob.data.profile.jobs[0].state != 'finished' || theJob.data.profile.jobs && theJob.data.profile.jobs[0].state != 'error') {
      console.log(this.state)
      theJob = await Axios.get("/api/create/job/" + theJob.data.profile._id)
      if (theJob.data.profile && theJob.data.profile.jobs && theJob.data.profile.jobs[0].state === 'finished' || theJob.data.profile && theJob.data.profile.jobs && theJob.data.profile.jobs[0].state === 'error') {
        break;
      }
      !theJob.data.profile.jobs ? console.log('no jobs') : console.log(theJob.data.profile.jobs[0]) // theJob.data.profile.jobs[0].renderProgress
      if (!theJob.data.profile.jobs) {
        // this.state.job.state = 'Submitted'
        this.setState(state => (state.job.state = 'Submitted', state))
      } else {
        // this.state.job.state = theJob.data.profile.jobs[0].state
        this.setState(state => (state.job.state = theJob.data.profile.jobs[0].state, state))
        if (theJob.data.profile.jobs[0].renderProgress) {
          // this.state.job.progress = theJob.data.profile.jobs[0].renderProgress
          this.setState(state => (state.job.progress = theJob.data.profile.jobs[0].renderProgress, state))
        }
      }
      // this.state.job.state = theJob.data.profile.jobs[0].state
      await new Promise(resolve => {
        setTimeout(resolve, 10 * 1000)
      })
    }
    this.setState(pre => ({
        isloading: false
    }))
    // var formData = new FormData(JSON.stringify(this.state.listing))
    /*Axios.post("/api/create/job", this.state.listing)
    // Axios({method:'post',url:'/blog/profile/create',data:formData,headers: { "content-type": "multipart/form-data" }})
    .then(data => {
      console.log(data.data.profile._id)
        // let profile = data.data.post._doc.username;
        // this.props.history.push('/')
        // this.props.match.navigate(0);
    }).catch(e => {
      console.log(e)
        this.setState({
            isloading: false,
            error: {
                ...this.state.error, message: e.response.data.message,
                code: e.response.status
            }
        });
    })*/
    }

    render() {
      // console.log(categories)

        let isLoading
        let iserror

        if (this.state.isloading) {
            isLoading = (
                <>
                    <div className="row centerAlign">
                                <div className="form-group col s12 m6 l6" style={{padding: '1.5rem 1rem'}}>
                                  <label htmlFor="address">Address </label>
                                  {/*<input
                                      type='text'
                                      name='username'
                                      value={this.state.listing.address || ''}
                                      className={"form-control " + (this.state.errors.username ? 'is-invalid' : '')}
                                      placeholder="Enter the Address"
                                      required
                                      readOnly={true}
                                      // onChange={this.myChangeHandler}
                                  />*/}
                                  <p>{this.state.listing.address
                                      ? this.state.listing.address : '3930 Wyoming St, St Louis, MO 63116'}</p>

                                  {this.state.errors.username.length > 0 &&
                                      <div className="mt-1"><span className='error text-danger'>{this.state.errors.username}</span></div>}
                                </div>
                                <div className="form-group col s12 m6 l6" style={{padding:'1.5rem 1rem', margin: '0'}}>
                                <div className="mar-20" style={{textAlign:'center'}}>
                                    {/*<Spinner />*/}
                                    {this.state.job.progress || this.state.listing.jobs && this.state.listing.jobs[0].progress ? console.log('hi') : null}
                                    <FontAwesomeIcon className="spinner" icon="fa-solid fa-spinner" />
                                    <p style={{fontWeight:'300', margin:'1rem 0 0.656rem 0', fontSize:'1.64rem', textTransform:'uppercase'}}>{this.state.job.state ? this.state.job.state : 'Wait'}</p>
                                    <div className="progress">
                                      <div className="determinate" style={{width: this.state.job.progress ? this.state.job.progress + "%"  : "0%"}}></div>
                                  </div>
                                  <p>Hold tight, rendering may take a minute...</p>


                                </div>
                                </div>
                                </div>
                </>
            )
        }

        if (this.state.error.code) {
            iserror = (
                <>
                    <div className="container error container-short">
                        <div className="mar-20">
                            <h5>Error Code - {this.state.error.code}</h5>
                            <h4>Error Message - {this.state.error.message}</h4>
                        </div>
                    </div>
                </>
            )
        }


        return (<>
            {/*{isLoading}*/}
            {iserror}
            <div className="container container-short" style={{minHeight:'75vh', paddingTop:'1rem'}}>
                <form id="form" onSubmit={this.mySubmitHandler} className="pt-4" encType='multipart/form-data'>
                <div style={{textAlign:'center'}} className="text-center">
                    <h2 className="text-center mb-3" style={{fontWeight:'700'}}>Real Estate Content Maker</h2>
                    <h5 className="text-center mb-3" style={{margin:'1rem 0 0.656rem 0'}}>Create a real estate slideshow video using Redfin</h5>
                  </div>
<div className="row" style={{margin: '5rem 1rem 0 0'}}>
                    {/*<div class="row">
          <form class="col s12">
            <div class="row">
              <div class="input-field col s6">
                <i class="material-icons prefix">local_hotel</i>
                <input id="icon_prefix" type="text" class="validate" />
                <label for="icon_prefix">Bedrooms</label>
              </div>
              <div class="input-field col s6">
                <i class="material-icons prefix">wc</i>
                <input id="icon_telephone" type="tel" class="validate" />
                <label for="icon_telephone">Bathrooms</label>
              </div>
            </div>
          </form>
        </div>*/}
        <div className="form-group">
          <label htmlFor="address">Enter Redfin Url </label>
          <input
              type='text'
              name='username'
              value={this.state.location.address}
              className={"form-control " + (this.state.errors.username ? 'is-invalid' : '')}
              placeholder="https://www.redfin.com/MO/Saint-Louis/30-Williamsburg-Rd-63141/home/93481456"
              required
              onChange={this.myChangeHandler}
              style={{border:'1.5px solid #e3bab3',borderRadius:'5px',padding:'5px'}}
          />

          {this.state.errors.username.length > 0 &&
              <div className="mt-1"><span className='error text-danger'>{this.state.errors.username}</span></div>}
        </div>

        <div className="form-group">
                  <button style={{ marginRight: '15px' }}
                      type='submit'
                      className="btn btn-primary btn-large waves-effect waves-light hoverable timbrBtn accent-3"
                      disabled={this.state.listing.address
                          ? '' : 'disabled'}
                      // onClick={(event) => event.preventDefault()}
                  >
                      Create Listing
                  </button>
              </div>

          {this.state.errors.username.length > 0 &&
              <div className="mt-1"><span className='error text-danger'>{this.state.errors.username}</span></div>}
        </div>

        {isLoading}

        <div style={{paddingTop:'3rem'}}>
        <h5>About the real estate content maker</h5>
        <p>Showcasing real estate by creating a video slideshow from images of a property is a perfect application of automated video creation.<br /><br />This demo allows you to create content using an available listing on redfin.com. Once a link to a property on redfin is enterd, a video slideshow is created, with the property details leading photographs of the property and ending with an agent's details.</p>
        </div>
        <div style={{paddingTop:'2rem'}}>
        <h5>How to create a property video using Redfin</h5>
        <p>Simply enter a url to a listing on <a href="https://www.redfin.com/" target="_blank">redfin.com</a> in the input above.</p>
        </div>
        <div style={{paddingTop:'2rem'}}>
        <h5>Next Steps to Personalization</h5>
        <p>Create an <a href="/register">account</a> and make a brand page to add your logo and agent details.</p>
        </div>

                    {/*<div className="form-group">
                      <label htmlFor="bedrooms">Bedroom/Bath </label>
                      <input
                          type='text'
                          name='username'
                          value={this.state.location.bedrooms}
                          className={"form-control " + (this.state.errors.username ? 'is-invalid' : '')}
                          placeholder="Enter the # of bedrooms & bathrooms"
                          required
                          onChange={this.myChangeHandler}
                      />

                      <div className="form-group">
                      <i class="material-icons prefix">account_circle</i>
                        <label htmlFor="bedrooms">Price </label>
                        <input
                            type='text'
                            name='username'
                            value={this.state.location.price}
                            className={"form-control " + (this.state.errors.username ? 'is-invalid' : '')}
                            placeholder="Enter the # of bedrooms & bathrooms"
                            required
                            onChange={this.myChangeHandler}
                        />
                        </div>

                        <div className="form-group">
                          <label htmlFor="SqFt">Square Feet </label>
                          <input
                              type='text'
                              name='username'
                              value={this.state.location.sqFt}
                              className={"form-control " + (this.state.errors.username ? 'is-invalid' : '')}
                              placeholder="Enter the # of bedrooms & bathrooms"
                              required
                              onChange={this.myChangeHandler}
                          />
                          </div>*/}

                </form>


            </div>
        </>
        )
    }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(RealEstate);
