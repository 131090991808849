import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { withRouter } from "../context/withRouter";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "./types";
// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      var userObj = {
        userId:res.data.user,
        token: token,
        expiration: res.data.expiration
      }
      localStorage.setItem('userData', JSON.stringify(userObj)/*res.data.user JSON.stringify(res.data.user)*/)
      // console.log(localStorage.getItem('userData'))
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      return axios.get('blog/profile/viewprofile');
      // history.push("/login")
    }) // re-direct to login on successful register
    .catch(err =>
      console.log(err)
      /*dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })*/
    );
};
// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage
// Set token to localStorage
// console.log(res)
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      var userObj = {
        userId:res.data.user,
        token: token,
        expiration: res.data.expiration
      }
      localStorage.setItem('userData', JSON.stringify(userObj)/*res.data.user JSON.stringify(res.data.user)*/)
      // console.log(localStorage.getItem('userData'))
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      return axios.get('blog/profile/viewprofile');
    })
    .then((data) => {
      let profile = data.data.profile.username
      localStorage.setItem(
          'profileData',
          JSON.stringify({
              "username": profile
          }))
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};
// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("profileData");
  localStorage.removeItem("userData");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
