import Axios from 'axios';
import React, { Component, useRef } from 'react'
import { Link, Navigate, useNavigate } from "react-router-dom";
import ImageUpload from '../../../Containers/ImageUpload/ImageUpload'
import Spinner from '../../../Containers/Spinner/Spinner';
import StaffTable from '../CreatePost/AddDeleteRow';
import Table from '../CreatePost/table';
import useAutosizeTextArea from "./useAutosizeTextArea";
import Chip from '@mui/material/Chip';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded';
import { Grid, Stack, Button } from "@mui/material";
// import "./style.scss";

import PropTypes from "prop-types";
import { compose } from 'redux';
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { withRouter } from "../../../context/withRouter";

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 320px;
    // font-family: IBM Plex Sans, sans-serif;
    // font-size: 0.875rem;
    // font-weight: 400;
    // line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

function isValidUrl(urlString) {
    try {
      return Boolean(new URL(urlString));
    }
    catch(e){
      return false;
    }
}

function addToCloudinary(file) {
  return new Promise((resolve, reject) => {
  const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload`;
  const xhr = new XMLHttpRequest();
  const fd = new FormData();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

  xhr.onreadystatechange = (e) => {
    if (xhr.readyState == 4 && xhr.status == 200) {
      const response = JSON.parse(xhr.responseText);
      console.log(response.secure_url);
      // return response
      resolve(response)
    }
  }

  fd.append(
    "upload_preset",
    process.env.REACT_APP_CLOUD_UNSIGNED
  );
  fd.append("tags", "browser_upload");
  fd.append("file", file);
  xhr.send(fd);
})
}

export class ArticleForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isloading: true,
      disable: false,
      logo:'',
      dimensions: [{name:'landscape',dimensions: {width: 1080,height: 566}},{name:'square',dimensions: {width: 1200,height: 1200}},{name:'stories',dimensions: {width: 1080,height: 1920}}],
      format: ['video','image']
      // blogError:'test'
      // title:"",
      // summary:[],
      // formValues: [{ heading: "", subheading : "", body:"", searchTerm:"", tags:[] }]
      // formValues:[]
     };
    // this.handleSubmit = this.handleSubmit.bind(this)
    this.mySubmitHandler = this.mySubmitHandler.bind(this)
    this.getBlog = this.getBlog.bind(this)
  }

  myChangeHandler(e) {
    // console.log(e.target.value)
    let title = this.state.title
    title = e.target.value
    this.setState({ title });
    // console.log(this.state)
  }

  componentDidMount() {
      console.log(this.props)
      let path = this.props.match.path
      let id = this.props.match.params.id
      if (path === `/create/${id}`) {
        // console.log('hi')
          this.setState(pre => ({
              isloading: true
          }))

          Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/content/"  + id).then(data => {
              let post = data.data
              // console.log(post[0].summary)
              /*if (data.data.contentArray) {
                console.log(data.data.contentArray)
                  var fetchedposts = data.data.contentArray.map((post, index) => (
                      <CreatePostForm key={index} {...post} {...index} />
                  ))
                  console.log(fetchedposts)
              }*/
              this.setState({
                  isloading: false,
                  formValues: post[0].summary,
                  title: post[0].title,
                  media:post[0].image || post[0].media,
                  source:post[0].source,
                  status:post[0].status
              });
              // var tx = document.getElementsByTagName("textarea");
              /*var tx = document.getElementsByClassName("textBody");
              // console.log(tx)
              const txHeight = 16;
              for (let i = 0; i < tx.length; i++) {
                if (tx[i].value == '') {
                  tx[i].setAttribute("style", "height:" + txHeight + "px;overflow-y:hidden;");
                } else {
                  tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
                }
                // tx[i].addEventListener("input", OnInput, false);
              }*/

              const chips = Array.from(document.querySelectorAll('.chip'));
              /*chips.map(chip => {
              	chip.addEventListener('click', function(e) {
                  console.log(e.currentTarget)
              		// e.currentTarget.classList.toggle('is-active');
                  // e.currentTarget.focus()
              		// e.target.focus();
              	});
              });*/
              if (this.props.auth) {
              Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/brands/" + this.props.auth.user.id).then(data => {
                  this.setState(pre => ({
                      isloading: false
                  }))
                  // console.log(data.data)
                  this.setState({ brands:data.data });
                  // console.log(this.state)
              })
                  .catch(e => {
                    console.log(e)
                  })
                }
          })
              .catch(e => {
                  this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });
              })
      }
      console.log(this.state)
  }

  handleChanges(e) {
    this.setState({[e.target.name]:e.target.value} )
  }

  handleChange(i, e) {
    // console.log(i)
      let link = isValidUrl(e.target.value)
    if (i!==null) {
      let formValues = this.state.formValues;
      if (link) {
        // console.log('chea')
        formValues[i]['media'] = e.target.value;
        formValues[i][e.target.name] = e.target.value;
      } else {
        let formValues = this.state.formValues;
        // console.log(formValues)
        formValues[i][e.target.name] = e.target.value;
      }
      this.setState({ formValues });
      // console.log(this.state)
    } else {
      // console.log(e)
      if (link) {
        // this.state.media = e.target.value
        // this.state.searchTerm = e.target.value
        this.setState({searchTerm:e.target.value,media:e.target.value})
      } else {
        // this.state.searchTerm = e.target.value
        this.setState({searchTerm:e.target.value})
      }
    }
  }

  handleNormalChange(e) {
    // let formValues = this.state.formValues;
    // console.log(e)
    // console.log(e.target)
    // formValues[i][e.target.name] = e.target.value;
    // console.log(this.state.brands.find(x=>x.name==e.target.value)._id)
    this.setState({ brand: e.target.value, brandId:this.state.brands.find(x=>x.name==e.target.value)._id})
    // console.log(this.state)
  }

  handleUrlChange(e) {
    // let url = this.state.url;
    // console.log(e.target.value)
    // formValues[i][e.target.name] = e.target.value;
    this.setState({ url:e.target.value });
  }

  inputHeight(e) {
    // console.log(e.currentTarget)
    e.currentTarget.focus()
  }

  addFormFields() {
    // console.log(this.state)
    if (this.state.formValues) {
      this.setState({ formValues: [...this.state.formValues, { heading: "", tags : [], body:"" }] })
    } else {
      this.setState({formValues: [{ heading: "", body:"", searchTerm:"", tags:[] }], title:''})
    }
    /*this.setState(({
      formValues: [...this.state.formValues, { heading: "", subheading : "", body:"" }]
    }))*/
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    if (formValues.length>0) {
      this.setState({ formValues });
    } else {
      this.setState({formValues:undefined})
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  handleClick(e,i,term) {
   console.info('You clicked the Chip.');
   var colors = e.currentTarget.style.backgroundColor
   console.log(colors)
   let formValues = this.state.formValues;
   console.log(formValues)
   if (e.currentTarget.style.backgroundColor == 'green') {
     e.currentTarget.style.backgroundColor = 'transparent'
     formValues[i]['searchTerm'] = formValues[i]['searchTerm'].replace(term,'').trim()
   } else {
     formValues[i]['searchTerm'] = formValues[i]['searchTerm'] ? formValues[i]['searchTerm'] += ' ' + term : term // e.target.value;
     e.currentTarget.style.backgroundColor = 'green'
   }
   this.setState({ formValues });
 };

  handleDelete() {
   console.info('You clicked the delete icon.');
 };

 onEnter(e) {
   // console.log(e.key)
   e.preventDefault();
   if (e.key==='Enter') {
     let link = isValidUrl(this.state.url)
     if (link) {

     // }
     this.setState({disable:true,url:this.state.url})
     Axios.post("https://zany-pink-dolphin-veil.cyclic.app/api/article/url", {url:this.state.url,creator:this.props.auth.user.id}).then(data => {
       // console.log(data)
       if (data.data) {
         this.props.match.navigate('/create/' + data.data._id);
         this.props.match.navigate(0)
       } else {
         alert('blog did not work, please insert manually')
         var url = new URL(this.state.url);
         var hostname = url.hostname;
         this.setState({blogError:true,source:hostname})
         // console.log(this.state)
       }
      })
    } else {
      alert('please provide a valid url')
    }
   }
   // console.log(e.target.value ? e.target.value : null)
 }

 getBlog(e) {
   let link = isValidUrl(this.state.url)
   if (this.props.auth && link) {
     console.log(this.state.url)
     this.setState(pre => ({
         isloading: true,
     }))
     this.setState({disable:true,url:this.state.url})
     if (this.state.content) {
       var contentObj = {content:this.state.content,image:this.state.image,url:this.state.url,title:this.state.title, creator:this.props.auth.user.id, source:this.state.source, status:'draft'}
     } else {
       var contentObj = {url:this.state.url, creator: this.props.auth.user.id, status:'draft'}
     }
     // console.log(this.state)
     // console.log(e.currentTarget.value)
    Axios.post("https://zany-pink-dolphin-veil.cyclic.app/api/article/url", contentObj).then(data => {
      // console.log(data)
      this.setState(pre => ({
          isloading: false
      }))
      if (data.data) {
        this.props.match.navigate('/create/' + data.data._id);
        this.props.match.navigate(0)
      } else {
        alert('blog did not work, please insert manually')
        var url = new URL(this.state.url);
        var hostname = url.hostname;
        this.setState({blogError:true,source:hostname})
        console.log(hostname)
        console.log(this.state)
      }
    })
  } else {
    alert('please provide a valid url')
  }
 }

 formFieldUp(i) {
   let formValues = this.state.formValues;
   // formValues.splice(i, 1);
   formValues.splice(i-1, 0, formValues.splice(i, 1)[0]);
   // formValues
   this.setState({ formValues });
 }

 formFieldDown(i) {
   let formValues = this.state.formValues;
   // formValues.splice(i, 1);
   formValues.splice(i+1, 0, formValues.splice(i, 1)[0]);
   // formValues
   this.setState({ formValues });
 }

 searchImg(i,e) {
   if (i!==null) {
   if (this.state.formValues[i].searchTerm) {
     console.log(this.state.formValues)
     Axios.post("https://zany-pink-dolphin-veil.cyclic.app/api/find/images", {searchTerm:this.state.formValues[i].searchTerm}).then(data => {
       console.log(data.data)
       /*let items = [...this.state.formValues];
       console.log(items[i])
       let item = {...items[i]};
       item.mediaArray = data.data
       items[i] = item;
       this.setState({items})*/
       this.setState({
        formValues: [
          ...this.state.formValues.slice(0, i),
          {
            ...this.state.formValues[i],
            mediaArray: data.data.slice(0,10),
          },
          ...this.state.formValues.slice(i + 1, this.state.formValues.length),
        ],
      });
       console.log(this.state.formValues[i])
     })
         .catch(e => {
             this.setState({
                 isloading: false,
                 error: {
                     ...this.state.error, message: e.response.data.message,
                     code: e.response.status
                 }
             });
         })
   }
 } else {
   if (this.state.searchTerm) {
     Axios.post("https://zany-pink-dolphin-veil.cyclic.app/api/find/images", {searchTerm:this.state.searchTerm}).then(data => {
       this.setState({mediaArray: data.data.slice(0,10)})
     })
     .catch(e => {
             this.setState({
                 isloading: false,
                 error: {
                     ...this.state.error, message: e.response.data.message,
                     code: e.response.status
                 }
             });
         })
     // console.log('huh')
   }
 }
   // console.log(this.state.formValues[i].searchTerm)
 }

 addMedia(url,i,e) {
   // console.log(this.state.formValues[i])
   // e.target.style.padding = '10px'
   console.info('You clicked the delete icon.');
   let tx = document.querySelectorAll('.elem' + i)
   for (let i = 0; i < tx.length; i++) {
     tx[i].style.padding = '0px'
     e.target.style.background = 'transparent'
   }
   // .map(x=>x.target.style.padding = '10px')
   // formValues[i]['media']

   if (i!==null) {
   let formValues = this.state.formValues;
   console.log(formValues)
   formValues[i]['media'] = url // e.target.value;
   this.setState({ formValues });
   e.target.style.padding = '10px'
   e.target.style.background = 'blue'
 } else {
   this.setState({media:url})
 }

 }

 createContent = async () => {
   let id = this.props.match.params.id
   if (this.props.auth) {
     for (var obj of this.state.formValues) {
       // console.log(obj)
       if (obj.files) {
         var media = await addToCloudinary(obj.files[0])
         obj.media = media.secure_url
         delete obj.files
       }
     }
     console.log(this.props.auth.user.id)
     let job = {}
     job.status = 'draft'
     job.creator = this.props.auth.user.id
     job.output = { dimensions: this.state.dimensions.find(x=>x.name===this.state.output).dimensions}
     /*job.output = { dimensions: {
       width: 1200,
       height: 1200,
     }}*/
     job.branding = {}
     job.branding.brandId = this.state.brandId

     job.content = {comps:this.state.formValues,title:this.state.title,media:this.state.media,source:this.state.source || this.state.brand}

     job.content.comps.map((element, index) => {
       delete element.mediaArray
     })
     job.templateId = ['64851167408e60e0d3766ad2','6485f427637510a2e1328ccc']
     console.log(job)
     Axios.patch("https://zany-pink-dolphin-veil.cyclic.app/api/content/" + id, {formValues:this.state.formValues}).then(form => {
         this.setState(pre => ({
             isloading: false
         }))
         Axios.post("https://zany-pink-dolphin-veil.cyclic.app/api/jobs/insert", job).then(data => {
             this.setState(pre => ({
                 isloading: false
             }))
             console.log(data.data)
             this.props.match.navigate('/job/' + data.data.insertedId);
             // this.setState({ brands:data.data });
             // console.log(this.state)
         })
             .catch(e => {
               console.log(e)
             })
         // this.props.history.push('/')
         // this.props.match.navigate(0);
     })
         .catch(e => {
           console.log(e)
         })
   } else {

   }
 }

 imageHandler = async(event,index) => {
   // console.log(event.target.files.length)
   // console.log(index)
   if (index>=0) {
     let formValues = this.state.formValues;
     formValues[index]['files'] = event.target.files// e.target.value;
     console.log(formValues)
   } else {
     this.state.files = event.target.files
     console.log(this.state)
   }
 }

  mySubmitHandler = async(e) => {
      this.setState(pre => ({
          isloading: true
      }))
      console.log(this.props)
      let path = this.props.match.path
      let id = this.props.match.params.id
      const { navigate } = this.props.match;
      let date = new Date()
      e.preventDefault()
      let formData;
      /*if (typeof (this.state.Post.imagePath) === "object") {
          formData = new FormData();
          formData.append('id', this.state.Post.id);
          formData.append('title', this.state.Post.title);
          formData.append('content', this.state.Post.content);
          formData.append('image', this.state.Post.imagePath, this.state.Post.title);
          formData.append('postDate', date.toString());
      }
      else {*/
          formData = {
              // "id": "63ac5dc2f7c3ee94456a2857",
              "content": this.state.formValues,
              'postDate': date.toString(),
              // "id": this.state.Post.id,
              // 'title': this.state.Post.title,
              // 'content': this.state.Post.content,
              // 'image': this.state.Post.imagePath,
              // 'postDate': date.toString(),
              // 'creator': '63ac5dc2f7c3ee94456a2857'
          }
          // console.log(formData)
      // }
      var summary = this.state.formValues
      for (var obj of summary) {
        console.log(obj)
        if (obj.files) {
          var media = await addToCloudinary(obj.files[0])
          obj.media = media.secure_url
          delete obj.files
        }
      }
      console.log(summary)

      if (path === `/create/${id}`) {
          Axios.patch("https://zany-pink-dolphin-veil.cyclic.app/api/content/" + id, {formValues:summary}).then(data => {
              this.setState(pre => ({
                  isloading: false
              }))
              // this.props.history.push('/')
              this.props.match.navigate(0);
          })
              .catch(e => {
                console.log(e)
                  /*this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });*/
              })
      }
      else {
        if (this.state.files) {
          var media = await addToCloudinary(this.state.files[0])
        } else {
          var media = {secure_url:this.state.media}
        }
          Axios.post("https://zany-pink-dolphin-veil.cyclic.app/api/content/create", {title:this.state.title,creator:this.props.auth.user.id,summary:summary,media:media.secure_url}).then(data => {
              this.setState(pre => ({
                  isloading: false
              }))
              console.log(data.data.insertedId)
              console.log(this.props)
              // navigate(0);
              // this.props.history.push('/')
              // this.props.history.push("/thePath")
              this.props.match.navigate('/create/' + data.data.insertedId);
          })
              .catch(e => {
                console.log(e)
                  /*this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });*/
              })
      }
      /*this.setState({
          formValues: formData.content
      });*/
  }

  render() {
    let isLoading

    if (this.state.isloading) {
        isLoading = (
            <>
                <div className="container loading">
                    <div className="mar-20">
                        <Spinner />
                    </div>
                </div>
            </>
        )
    }

    return (

      <>
      {/*isLoading*/}
      { this.state.formValues ?
        <div className="container" style={{minHeight: '65vh'}}>
        <form  onSubmit={this.mySubmitHandler}>
        {/*<h5>{this.state.title}</h5>*/}
        <div className="form-group">
        {/*<label htmlFor="title">Title</label>*/}
        <div className="input-field col s6">
        <StyledTextarea
          // aria-label="minimum height"
          style={{fontSize:'16px',width:'100%'}}
          name="title"
          onChange={e => this.handleChanges(e)}
          value={this.state.title || ""}
          minRows={1}
          placeholder="Content title"
          required="required"
        />
        </div>
        </div>

        {!this.state.media ?

          <div className="form-group">
            <div className="input-field col s6" style={{display:'flex'}}>

            <IconButton aria-label="delete" style={{background:"#ffffff",height: '100%', margin:'auto'}}>
            <Button
              component="label"
              style={{left:'0',minWidth:'0px'}}
            >
              <input
                type="file"
                accept=".jpg,.png,.jpeg"
                value={this.state.file}
                onInput={e=>this.imageHandler(e)}
                hidden
              />
              <InsertPhotoRoundedIcon />
            </Button>
            </IconButton>

            <StyledTextarea
              style={{fontSize:'16px',width:'100%',margin:'0 10px'}}
              name="searchTerm"
              onChange={e => this.handleChange(null, e)}
              value={this.state.searchTerm || ""}
              minRows={1}
              placeholder="Search Term"
              // required="required"
            />

            <IconButton aria-label="delete" style={{background:"#ffffff",height: '100%', margin:'auto'}} onClick={e => this.searchImg(null, e)}>
              <SearchIcon />
            </IconButton>

            </div>
            {this.state.mediaArray ? <Box sx={{ width: '100%', overflowY: 'scroll', margin: '0,auto'}}>
              <ImageList variant="masonry" cols={3} gap={8}>
                {this.state.mediaArray.slice(0,10).map((item,i) => (
                  <ImageListItem key={item.url}>
                    <img
                      src={`${item.url}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      className={'elem' + i}
                      onClick={e=>this.addMedia(item.url,null,e)}
                      alt={item.origin.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box> : null}
          </div>

      : null }



          { this.state.formValues ?
            this.state.formValues.map((element, index) => (
            <div key={index} style={{padding:'15px',background:'rgb(255, 247, 239)',marginBottom: '25px', marginTop:'25px'}}>

            <div className="form-group">
            <label htmlFor="title">Title</label>
            <div className="input-field col s6">
            <StyledTextarea
              // aria-label="minimum height"
              style={{fontSize:'16px',width:'100%'}}
              name="heading"
              onChange={e => this.handleChange(index, e)}
              value={element.heading || ""}
              minRows={1}
              placeholder="Enter the title"
              // required="required"
            />
            </div>
            </div>

            {/*<div className="form-group">
                <label htmlFor="title">Title </label>
                <input
                    type='text'
                    name='heading'
                    value={element.heading || ""}
                    className="form-control"
                    placeholder="Enter the title"
                    // required
                    onChange={e => this.handleChange(index, e)}
                />

            </div>*/}

            <div className="form-group">
            <label htmlFor="title">Body Copy</label>
            <div className="input-field col s6">
            <StyledTextarea
              // aria-label="minimum height"
              style={{fontSize:'16px',width:'100%'}}
              name="body"
              onChange={e => this.handleChange(index, e)}
              value={element.body || ""}
              minRows={3}
              placeholder="Enter the description"
              required="required"
            />
            </div>
            </div>

            {/*<div className="form-group">
                <label htmlFor="description">Description </label>
                <textarea
                    type='text'
                    name='body'
                    rows="4"
                    value={element.body || ""}
                    className={"form-control materialize-textarea textBody"}
                    placeholder="Enter the description"
                    // height='79px !important'
                    // ref={textAreaRef}
                    // style={{height:'79px'}}
                    required="required"
                    // onKeyPress={e => this.inputHeight(e)}
                    // onKeyPress= {this.style.height = ((this.value.length + 1) * 16) + 'px';}
                    onChange={e => this.handleChange(index, e)}
                />

            </div>*/}

            {/*<Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                <Stack direction="row" spacing={1}>
                  <Button variant="outlined" startIcon={<InsertPhotoRoundedIcon />}>

                  </Button>
                  <Button variant="contained" endIcon={<InsertPhotoRoundedIcon />}>

                  </Button>
                </Stack>

                </Grid>
                <Grid item xs={8}>
                  <div className="form-group">
                    <div><label htmlFor="title">Media</label></div>
                    <StyledTextarea
                      // aria-label="minimum height"
                      style={{fontSize:'16px', width:'100%'}}
                      name="searchTerm"
                      onChange={e => this.handleChange(index, e)}
                      value={element.searchTerm || ""}
                      minRows={1}
                      placeholder="Enter url to searchTerm"
                      // required="required"
                    />
                    </div>
                </Grid>
              </Grid>
            </Box>*/}

            <div className="form-group">
            <label htmlFor="title">Media</label>
              {/*<div><i className="material-icons prefix" onClick={e => this.searchImg(index, e)}>search</i></div>*/}
              <div className="input-field col s6" style={{display:'flex'}}>

              <IconButton aria-label="delete" style={{background:"#ffffff",height: '100%', margin:'auto'}}>
              <Button
                // variant="contained"
                component="label"
                style={{left:'0',minWidth:'0px'}}
              >
                <input
                  type="file"
                  accept=".jpg,.png,.jpeg"
                  value={element.file}
                  onInput={e=>this.imageHandler(e,index)}
                  hidden
                />
                <InsertPhotoRoundedIcon />
              </Button>
              </IconButton>


              {/*<input
                id={'props.id'}
                name={'props.name'}
                // ref={filePickerRef}
                // style={{ display: 'none' }}
                type="file"
                accept=".jpg,.png,.jpeg"
                // onChange={pickedHandler}
              />*/}

              {/*<ImageUpload
                  id="logo"
                  name="logo"
                  text="Upload"
                  // onInput={this.imageHandler}
                  value={element.file}
                  // errorText="Please provide a logo."
              />*/}

              <StyledTextarea
                // aria-label="minimum height"
                style={{fontSize:'16px',width:'100%',margin:'0 10px'}}
                name="searchTerm"
                onChange={e => this.handleChange(index, e)}
                value={element.searchTerm || ""}
                minRows={1}
                placeholder="Search Term"
                // required="required"
              />

              <IconButton aria-label="delete" style={{background:"#ffffff",height: '100%', margin:'auto'}} onClick={e => this.searchImg(index, e)}>
                <SearchIcon />
              </IconButton>

              </div>
            </div>

            {/*<div className="form-group">
            <label htmlFor="title">Media </label>
            <div className="input-field col s6">
                <i className="material-icons prefix" onClick={e => this.searchImg(index, e)}>search</i>
                <input
                    type='text'
                    name='searchTerm'
                    value={element.searchTerm || ""}
                    className="form-control"
                    placeholder="Enter url to searchTerm"
                    // required
                    onChange={e => this.handleChange(index, e)}
                />
                </div>

            </div>*/}

            {/*<span className="c-chips-intro_text">Select your interest</span>
            <div className="chips chips-initial"></div>*/}
            <div>
            {element.tags.map((recipe,i) => {
              return <Chip
                key={i}
                label= {recipe.word}
                variant="outlined"
                onClick={e => this.handleClick(e,index,recipe.word)}
                // onDelete={e => this.handleDelete(e)}
                style={{margin:'10px 10px 0 0'}}
              />// <div>{recipe.word}</div>
            })}
            </div>
            {element.mediaArray ?
              <Box sx={{ width: '100%', overflowY: 'scroll', margin: '0,auto'}}>
                <ImageList variant="masonry" cols={3} gap={8}>
                  {element.mediaArray.slice(0,10).map((item) => (
                    <ImageListItem key={item.url}>
                      <img
                        src={`${item.url}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        className={'elem' + index}
                        onClick={e=>this.addMedia(item.url,index,e)}
                        alt={item.origin.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>

               :

               null}


            {/*{
              index ?*/}
                <div style={{textAlign:'right', margin: '25px 0 0 0'}}><IconButton style={{padding:0}} aria-label="Example" onClick={() => this.formFieldUp(index)}>{<ArrowCircleUpIcon style={{height:'1.5em',width:'1.5em'}} />}</IconButton><IconButton style={{padding:0}} aria-label="Example" onClick={() => this.removeFormFields(index)}>{<HighlightOffIcon style={{height:'1.5em',width:'1.5em',color:'red'}} />}</IconButton><IconButton style={{padding:0}} aria-label="Example" onClick={() => this.formFieldDown(index)}>{<ArrowCircleDownIcon style={{height:'1.5em',width:'1.5em'}} />}</IconButton></div>
              {/*: null
            }*/}

            </div>

          )) : null }

          <div className="form-group" style={{paddingTop:'15px'}}>
              <button style={{ marginRight: '15px' }}
                  type='button'
                  className="btn btn-primary"
                  onClick={() => this.addFormFields()}
                  disabled={this.state.formValues
                      ? '' : 'disabled'}
              >
                  Add
              </button>
              <button style={{ marginRight: '15px' }}
                  type='submit'
                  className="btn btn-primary"
                  disabled={this.state.formValues && this.state.title && this.state.files || this.state.media
                      ? '' : 'disabled'}
              >
                  Save
              </button>
          </div>

          <div className="form-group" style={{paddingTop:'15px'}}>
            {/*<button style={{ marginRight: '15px' }}
                type='button'
                className="btn btn-primary"
                onClick= {() => this.createContent()}
                disabled={this.state.status && this.state.status !== 'published'
                    ? '' : 'disabled'}
            >
                Publish
            </button>*/}
            <button style={{ marginRight: '15px' }}
                type='button'
                className="btn btn-primary"
                onClick= {()=> window.open("/preview/" + this.props.match.params.id, "_blank")}
                disabled={this.state.status && this.state.status !== 'published'
                    ? '' : 'disabled'}
            >
                Preview
            </button>
          </div>

          {this.state.brands ?
            <FormControl fullWidth style={{marginTop: '20px'}}>
              <InputLabel id="demo-simple-select-label">Branding</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.brand || ''}
                label="Brand"
                onChange={e => this.handleNormalChange(e)}
              >
                {this.state.brands.map((item, index) => (
                  <MenuItem key={index} name={item.name} value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl> : null
          }

          <FormControl fullWidth style={{marginTop: '20px'}}>
            <InputLabel id="demo-simple-select-label">Output</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.output || ''}
              label="Output"
              name='output'
              onChange={e => this.handleChanges(e)}
            >
              {this.state.dimensions.map((item, index) => (
                <MenuItem key={index} name={item.name} value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <button style={{ marginRight: '15px', marginTop:'20px' }}
              type='button'
              onClick= {() => this.createContent()}
              className="btn btn-primary"
              disabled={this.state.formValues && this.state.formValues.filter(x=>x.media || x.files).length == this.state.formValues.length && this.state.brandId && this.state.output
                  ? '' : 'disabled'}
          >
              Create
          </button>

      </form>
      </div>
    : this.state.blogError ?

    <div className="container" style={{minHeight: '65vh'}}>
    <h4 style={{fontWeight:'700', textAlign: 'center'}}>Manually Input Blog Info</h4>
     <form>
     <div className="form-group">
     <label htmlFor="title">Blog Title</label>
      <StyledTextarea
        aria-label="minimum height"
        minRows={1}
        // value={this.state.url ? this.state.url : ''}
        name="title"
        onChange={e => this.handleChanges(e)}
        placeholder="Blog Title"
      />
      </div>
      <div className="form-group">
      <label htmlFor="title">Blog Copy</label>
      <StyledTextarea
        aria-label="minimum height"
        name="content"
        onChange={e => this.handleChanges(e)}
        minRows={3}
        placeholder="Paste Blog text here..."
      />
      </div>
      <div className="form-group">
      <label htmlFor="title">Featured Image</label>
      <StyledTextarea
        aria-label="minimum height"
        minRows={1}
        name="image"
        onChange={e => this.handleChanges(e)}
        placeholder="Featured Image url"
      />
      </div>
      <button style={{ marginRight: '15px', marginTop:'20px' }}
          type='button'
          onClick= {e => this.getBlog(e)}
          className="btn btn-primary"
          disabled={this.state.content && this.state.image && this.state.url && this.state.title
              ? '' : 'disabled'}
      >
          Create
      </button>
    </form>
    </div>

    :
    <div className="container" style={{height: '65vh', display:'flex',justifyContent:'center',alignItems:'center', marginTop:'6rem',marginBottom:'9rem'}}>
    <div style={{textAlign:'center',width:'100%'}}>
    <h4 style={{marginBottom:'25px',fontWeight:700}}>Content Summarizer</h4>
    <Paper
      component="form"
      onSubmit={this.onEnter}
      style={{backgroundColor:'#FFF7EF', width: '90%', margin: '0 auto',borderRadius:'25px', marginBottom:'15px'}}
      // onKeyUp={e => this.onEnter(e)}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
    >
    <div className="form-group" style={{width: '100%', borderRadius: '25px'}}>
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={e => this.getBlog(e)} style={{borderRadius:'0px', padding:'0px'}}>
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Blog Url"
        name='url'
        value={this.state.url || ""}
        onKeyUp={e => this.onEnter(e)}
        onChange={e => this.handleUrlChange(e)}
        inputProps={{ 'aria-label': 'Blog Url', style: {borderBottom:'0px'} }}
        style={{width:'75%', borderBottom:'0px'}}
      />

    </div>
    </Paper>
    <button style={{ marginRight: '15px', marginTop:'20px' }}
        type='button'
        onClick= {e => this.getBlog(e)}
        className="btn btn-primary"
        disabled={this.state.url && this.state.disable == false
            ? '' : 'disabled'}
    >
        Create
    </button>
    <button style={{ marginRight: '15px', marginTop:'20px' }}
        type='button'
        onClick={() => this.addFormFields()}
        className="btn btn-primary"
    >
        BYOB
    </button>
    </div></div>}
      {// empty array form
      /*<Paper
        component="form"
        onSubmit={this.onEnter}
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
      >
      <div className="form-group" style={{width: '100%', borderRadius: '25px'}}>
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={e => this.getBlog(e)} style={{borderRadius:'0px'}}>
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Blog Url"
          name='url'
          value={this.state.url || ""}
          // onKeyDown={e => this.onEnter(e)}
          onChange={e => this.handleUrlChange(e)}
          inputProps={{ 'aria-label': 'Blog Url' }}
          style={{width:'80%', border:'0'}}
        />

      </div>
      </Paper>*/

      /*<div className="form-group">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Google Maps"
          name='url'
          value={this.state.url || ""}
          onChange={e => this.handleUrlChange(e)}
          inputProps={{ 'aria-label': 'search google maps' }}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={e => this.getBlog(e)}>
          <SearchIcon />
        </IconButton>
      </div>*/

      /*<div className="button-section">
          <button className="button add" type="button" onClick={() => this.addFormFields()}>Add</button>
          <button className="button submit" type="submit">Submit</button>
      </div>*/}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(ArticleForm);
