import React, { Component } from "react";
import { Link } from "react-router-dom";
import {AnimatedText} from './movingText';

const FADE_INTERVAL_MS = 1750

type FadeProp = { fade: 'fade-in' | 'fade-out' }

class Landing extends Component {
  render() {
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align">
            {/*<h4>
              <b>Turn</b> your database into shareable content a login/auth app with the{" "}
              <span style={{ fontFamily: "monospace" }}>MERN</span> stack from
              scratch
            </h4>*/}
            {/*<h2 style={{fontWeight:"800"}}>Let’s <span style={{textDecorationLine: 'underline', textTransform:'uppercase'}}> develop</span> Together</h2>*/}
            <AnimatedText />
            <p className="flow-text grey-text text-darken-1">
            🤝
            <br/>
              Create, automate and personalize any process. Seamlessly.
            </p>
            <br />
            <div className="col s6" style={{textAlign: "right"}}>
              <Link
                to="/register"
                style={{
                  width: "140px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px"
                }}
                className="btn btn-large waves-effect waves-light hoverable timbrBtn accent-3"
              >
                Register
              </Link>
            </div>
            <div className="col s6" style={{textAlign: "left"}}>
              <Link
                to="/login"
                style={{
                  width: "140px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px"
                }}
                className="btn btn-large waves-effect hoverable white black-text"
              >
                Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Landing;
