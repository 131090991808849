import { useEffect, useState } from 'react'
import "../../App.css";

const FADE_INTERVAL_MS = 1750
const WORD_CHANGE_INTERVAL_MS = FADE_INTERVAL_MS * 2
const WORDS_TO_ANIMATE = ['Create', 'Develop', '  Build ', '   Grow   ']

// type FadeProp = { fade: 'fade-in' | 'fade-out' }

export const AnimatedText = () => {
  // const [fadeProp, setFadeProp] = useState<FadeProp>({ fade: 'fade-in' })
  const [fadeProp, setFadeProp] = useState({
          fade: 'fade-in',
      });
  const [wordOrder, setWordOrder] = useState(0)

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      fadeProp.fade === 'fade-in' ? setFadeProp({ fade: 'fade-out' }) : setFadeProp({ fade: 'fade-in' })
    }, FADE_INTERVAL_MS)

    return () => clearInterval(fadeTimeout)
  }, [fadeProp])

  useEffect(() => {
    const wordTimeout = setInterval(() => {
      setWordOrder((prevWordOrder) => (prevWordOrder + 1) % WORDS_TO_ANIMATE.length)
    }, WORD_CHANGE_INTERVAL_MS)

    return () => clearInterval(wordTimeout)
  }, [])

  return (
    <h2 style={{fontWeight:'800'}}>
      Let's <span style={{display:'inline-block',borderBottom:'5px solid #000000', width: '235px', marginBottom:'-8px'}}></span><span style={{display: 'inline-block', marginLeft:'-237px',width:'235px', textTransform:'uppercase', color:'#849974'}} className={fadeProp.fade}>{WORDS_TO_ANIMATE[wordOrder]}</span> Together
    </h2>
  )
}
