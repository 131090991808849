import Axios from 'axios';
import React, { Component, useRef } from 'react'
import { Link, Navigate, useNavigate } from "react-router-dom";
import ImageUpload from '../../../Containers/ImageUpload/ImageUpload'
import Spinner from '../../../Containers/Spinner/Spinner';
import StaffTable from '../CreatePost/AddDeleteRow';
import Table from '../CreatePost/table';
import useAutosizeTextArea from "./useAutosizeTextArea";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import ArticleForm from './articleFormComponent';
// import "./style.scss";

import PropTypes from "prop-types";
import { compose } from 'redux';
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { withRouter } from "../../../context/withRouter";

export class CreatePostForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      // title:"",
      imgGallery: false,
      summary:[],
      // formValues: [{ heading: "", subheading : "", body:"", searchTerm:"", tags:[] }]
      formValues:[]
     };
    // this.handleSubmit = this.handleSubmit.bind(this)
    // this.mySubmitHandler = this.mySubmitHandler.bind(this)
  }

  myChangeHandler(e) {
    // console.log(e.target.value)
    let title = this.state.title
    title = e.target.value
    this.setState({ title });
    // console.log(this.state)
  }

  componentDidMayMount() {
      // console.log(this.props)
      let path = this.props.match.path
      let id = this.props.match.params.id
      if (path === `/create/${id}`) {
        // console.log('hi')
          this.setState(pre => ({
              isloading: true
          }))

          Axios.get("https://zany-pink-dolphin-veil.cyclic.app/api/content/"  + id).then(data => {
              let post = data.data
              // console.log(post[0].summary)
              /*if (data.data.contentArray) {
                console.log(data.data.contentArray)
                  var fetchedposts = data.data.contentArray.map((post, index) => (
                      <CreatePostForm key={index} {...post} {...index} />
                  ))
                  console.log(fetchedposts)
              }*/
              this.setState({
                  isloading: false,
                  formValues: post[0].summary
              });
              var tx = document.getElementsByTagName("textarea");
              // console.log(tx)
              const txHeight = 16;
              for (let i = 0; i < tx.length; i++) {
                if (tx[i].value == '') {
                  tx[i].setAttribute("style", "height:" + txHeight + "px;overflow-y:hidden;");
                } else {
                  tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
                }
                // tx[i].addEventListener("input", OnInput, false);
              }

              const chips = Array.from(document.querySelectorAll('.chip'));
              /*chips.map(chip => {
              	chip.addEventListener('click', function(e) {
                  console.log(e.currentTarget)
              		// e.currentTarget.classList.toggle('is-active');
                  // e.currentTarget.focus()
              		// e.target.focus();
              	});
              });*/
          })
              .catch(e => {
                  this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });
              })
      }
      console.log(this.state)
  }

  handleChange(i, e) {
    let formValues = this.state.formValues;
    console.log(formValues)
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  inputHeight(e) {
    console.log(e.currentTarget)
    e.currentTarget.focus()
  }

  addFormFields() {
    // console.log(this.state)
    this.setState({ formValues: [...this.state.formValues, { heading: "", tags : [], body:"" }] })
    /*this.setState(({
      formValues: [...this.state.formValues, { heading: "", subheading : "", body:"" }]
    }))*/
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  handleClick(e) {
   console.info('You clicked the Chip.');
   var colors = e.currentTarget.style.backgroundColor
   console.log(colors)
   if (e.currentTarget.style.backgroundColor == 'green') {
     e.currentTarget.style.backgroundColor = 'transparent'
   } else {
     e.currentTarget.style.backgroundColor = 'green'
   }
 };

  handleDelete() {
   console.info('You clicked the delete icon.');
 };

 formFieldUp(i) {
   let formValues = this.state.formValues;
   // formValues.splice(i, 1);
   formValues.splice(i-1, 0, formValues.splice(i, 1)[0]);
   // formValues
   this.setState({ formValues });
 }

 formFieldDown(i) {
   let formValues = this.state.formValues;
   // formValues.splice(i, 1);
   formValues.splice(i+1, 0, formValues.splice(i, 1)[0]);
   // formValues
   this.setState({ formValues });
 }

 searchImg(i,e) {
   if (this.state.formValues[i].searchTerm) {
     console.log(this.state.formValues)
     Axios.post("https://zany-pink-dolphin-veil.cyclic.app/api/find/images", {searchTerm:this.state.formValues[i].searchTerm}).then(data => {
       console.log(data.data)
       /*let items = [...this.state.formValues];
       console.log(items[i])
       let item = {...items[i]};
       item.mediaArray = data.data
       items[i] = item;
       this.setState({items})*/
       this.setState({
        formValues: [
          ...this.state.formValues.slice(0, i),
          {
            ...this.state.formValues[i],
            mediaArray: data.data,
          },
          ...this.state.formValues.slice(i + 1, this.state.formValues.length),
        ],
      });
       console.log(this.state.formValues[i])
     })
         .catch(e => {
             this.setState({
                 isloading: false,
                 error: {
                     ...this.state.error, message: e.response.data.message,
                     code: e.response.status
                 }
             });
         })
   }
   // console.log(this.state.formValues[i].searchTerm)
 }

  mySubmitHandler(e) {
      this.setState(pre => ({
          isloading: true
      }))
      let path = this.props.match.path
      let id = this.props.match.params.id
      const { navigate } = this.props.match;
      let date = new Date()
      e.preventDefault()
      let formData;
      /*if (typeof (this.state.Post.imagePath) === "object") {
          formData = new FormData();
          formData.append('id', this.state.Post.id);
          formData.append('title', this.state.Post.title);
          formData.append('content', this.state.Post.content);
          formData.append('image', this.state.Post.imagePath, this.state.Post.title);
          formData.append('postDate', date.toString());
      }
      else {*/
          formData = {
              // "id": "63ac5dc2f7c3ee94456a2857",
              "content": this.state.formValues,
              'postDate': date.toString(),
              // "id": this.state.Post.id,
              // 'title': this.state.Post.title,
              // 'content': this.state.Post.content,
              // 'image': this.state.Post.imagePath,
              // 'postDate': date.toString(),
              // 'creator': '63ac5dc2f7c3ee94456a2857'
          }
          // console.log(formData)
      // }

      if (path === `/tryme/${id}`) {
          Axios.put("/blog/post/" + id, formData).then(data => {
              this.setState(pre => ({
                  isloading: false
              }))
              // this.props.history.push('/')
              this.props.match.navigate(0);
          })
              .catch(e => {
                  this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });
              })
      }
      else {
          Axios.post("/blog/post", formData).then(data => {
              this.setState(pre => ({
                  isloading: false
              }))
              navigate(0);
              // this.props.history.push('/')
          })
              .catch(e => {
                // console.log(e)
                  this.setState({
                      isloading: false,
                      error: {
                          ...this.state.error, message: e.response.data.message,
                          code: e.response.status
                      }
                  });
              })
      }
      this.setState({
          formValues: formData.content
      });
  }

  render() {

    return (
      /*<div className="container" style={{height: '75vh'}}>*/
         <ArticleForm parentToChild={this.state.formValues}/>
      /*</div>*/
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default compose(
  withRouter,              // <-- injects a params prop
  connect(mapStateToProps) // <-- props.params accessible
)(CreatePostForm);
